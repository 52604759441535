import { EVENTS, TRACKING_TEMPLATE_CODES } from './events';

class Tracking {
  static TEMPLATE_TOKEN_PATTERN = /\[[A-Z_,0-9/]+\]/g;

  static getLabel(template, data) {
    return template.replace(
      Tracking.TEMPLATE_TOKEN_PATTERN,
      Tracking.$renderLabelSegment.bind(null, data),
    );
  }

  static $renderLabelSegment(data, match) {
    const str = data[TRACKING_TEMPLATE_CODES[match]];
    return str || '';
  }

  // eslint-disable-next-line
  gtag(config) {
    window.ga('send', config);
  }

  init() {
    this.events = EVENTS;
  }

  trackEvent(event, vars) {
    const eventConfig = { ...this.events[event] };
    eventConfig.eventLabel = Tracking.getLabel(eventConfig.eventLabel, vars);
    this.gtag(eventConfig);
  }

  // eslint-disable-next-line
  trackPageView(page) {
    this.gtag({ event: 'Page View', page });
  }
}

export default Tracking;
