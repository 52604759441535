<template>
  <div class="ending-animation" :class="{ visible, safari: isSafari }">
    <div class="ending-animation__lottie">
      <div class="ending-animation__lottie__dual ending-animation__lottie__dual--type" ref="type" />
      <div class="ending-animation__lottie__dual ending-animation__lottie__dual--body" ref="body" />
    </div>
  </div>
</template>

<script>
import * as lottie from 'lottie-web';

export default {
  props: {
    animationData: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    isSafari: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    bodyLottie: null,
    typeLottie: null,
  }),
  watch: {
    visible() {
      this.bodyLottie.play();
      this.typeLottie.play();
    },
  },
  mounted() {
    this.buildBody();
    this.buildType();
  },
  methods: {
    buildBody() {
      this.bodyLottie = lottie.loadAnimation({
        container: this.$refs.body,
        animationData: this.animationData.body,
        renderer: 'svg',
        autoplay: false,
        loop: 2,
      });
    },
    buildType() {
      this.typeLottie = lottie.loadAnimation({
        container: this.$refs.type,
        animationData: this.animationData.type,
        renderer: 'svg',
        autoplay: false,
        loop: 2,
      });
      this.typeLottie.addEventListener('complete', () => {
        this.$emit('final-anim-complete');
      });
    },
  },
};
</script>

<style lang="scss">
.ending-animation {
  position: absolute;
  width: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 400ms $easeOutQuad;
  z-index: 10;
  &.visible {
    opacity: 1;
  }
  &.safari {
    .ending-animation {
      &__lottie {
        svg {
          filter: none;
        }
      }
    }
  }
  &__lottie {
    width: 100%;
    position: relative;
    svg, img {
      pointer-events: none;
      width: 100%;
      filter: url(#pixelate);
    }
    &__dual {
      &--type {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: -1;
        svg {
          filter: none;
        }
      }
    }
  }
}
</style>
