<template>
<div id="app">
  <router-view @page-ready="handlePageReady" @show-intro="showIntro = true;" v-if="showLoader"/>
  <transition name="fade-slow">
    <div class="site-intro" :class="{ visible: showLoader }" v-if="showIntro">
      <img :src="loader" class="site-intro__loader" @load="imagesLoaded += 1;" alt="pizza loader">
      <img class="loading" :src="loading" @load="imagesLoaded += 1;" alt="loading">
    </div>
  </transition>
</div>
</template>

<script>
import loader from '@/assets/images/pizza-loader.gif';
import loading from '@/assets/images/loading.png';

export default {
  data: () => ({
    showIntro: true,
    imagesLoaded: 0,
    loader,
    loading,
  }),
  computed: {
    showLoader() {
      return this.imagesLoaded === 2;
    },
  },
  mounted() {
    document.body.classList.add('locked');
    document.addEventListener('keydown', this.handleTab);
    document.addEventListener('click', this.handleMouse);
  },
  methods: {
    handlePageReady() {
      document.body.classList.remove('locked');
      this.showIntro = false;
    },
    handleTab({ code }) {
      if (code === 'Tab') {
        document.body.classList.remove('using-mouse');
        document.body.classList.add('using-tabs');
      }
    },
    handleMouse() {
      document.body.classList.remove('using-tabs');
      document.body.classList.add('using-mouse');
    },
  },
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Aldrich&family=Press+Start+2P');
@import '~@/styles/_normalize';
@import '~@/styles/scaffold';

#app {
  .site-intro {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #112A45;
    z-index: 1000;
    &.visible {
      .site-intro {
        &__loader {
          opacity: 1;
        }
      }
      .loading {
        opacity: 1;
      }
    }
    &__loader {
      max-width: 200px;
      opacity: 0;
      transition: opacity 300ms $easeOutQuad;
    }
    .loading {
      transition: opacity 300ms $easeOutQuad;
      opacity: 0;
      width: 200px;
      margin-top: 50px;
    }
  }
}
</style>
