<template>
  <div class="codepen-container" id="editor">
    <Marquee />
    <div class="editor-container">
      <div class="editor" id="game-window">
        <span class="editor__border">
          <editorBorder />
        </span>
        <div class="editor__header">
          <ul class="editor__header__nav">
            <li :class="{active: activeTab === 'html'}">
              <a @click="setActiveTab('html', true)">HTML</a>
            </li>
            <li :class="{active: activeTab === 'css'}">
              <a @click="setActiveTab('css', true)">CSS</a>
            </li>
            <li :class="{active: activeTab === 'js'}">
              <a @click="setActiveTab('js', true)">JS</a>
            </li>
          </ul>
          <div class="editor__header__content">
            <div class="spacer" />
            <a
              class="odu-copy"
              href="https://www.odu.edu/compsci"
              target="_blank"
              @click="tracking.trackEvent('e-game-odu-cs-click')"
            >
              <oduLogo />
              <span>
                POWERED BY THE<br>
                <span class="underline">
                  DEPARTMENT OF COMPUTER SCIENCE
                  <downArrow class="arrow" />
                </span>
              </span>
            </a>
          </div>
        </div>
        <div class="editor__body">
          <div class="editor__tabs">
            <button class="restart-button" @click="restartGame" :class="{ visible: gameStarted }">
              Restart
              <restart />
            </button>
            <div
              class="editor__tabs__tab editor__tabs__tab--html"
              ref="html"
              :class="{active: activeTab === 'html'}"
            />
            <div
              class="editor__tabs__tab editor__tabs__tab--css"
              ref="css"
              :class="{active: activeTab === 'css'}"
            />
            <div
              class="editor__tabs__tab editor__tabs__tab--javascript"
              ref="javascript"
              :class="{active: activeTab === 'js'}"
            />
          </div>
          <PizzaApp
            ref="pizzaApp"
            :codeValues="values"
            @step-change="handleStepChange"
            @game-start="handleGameStart"
            @welcome-loaded="$emit('loaded')"
            @welcome-play="handleWelcomePlay"
            @game-done="gameStarted = false;"
            :canAnimate="canAnimate"
            :contestEnded="contestEnded"
            :isSafari="isSafari"
          />
        </div>
      </div>
    </div>
    <div class="odu-callout" id="callout">
      <span class="odu-callout__crown">
        <img :src="crown" alt="odu crown" />
      </span>
      <a class="odu-callout__inner" href="https://www.odu.edu/compsci" target="_blank">
        <img :src="goldBar" class="odu-callout__inner__background" alt="info background">
        <div class="odu-callout__inner__content">
          <span class="odu-callout__inner__content__head">
            <img :src="bigBlueHead" alt="odu mascot glasses eating pizza" />
          </span>
          <div class="odu-callout__inner__content__copy">
            <h5>
              <span>DID YOU KNOW</span>:
            </h5>
            <p>
              {{calloutCopy}}
            </p>
          </div>
        </div>
      </a>
      <span class="odu-callout__crown">
        <img :src="crown" alt="odu crown" />
      </span>
    </div>
  </div>
</template>

<script>
import CodeMirror from 'codemirror';
import xml from 'codemirror/mode/xml/xml';
import css from 'codemirror/mode/css/css';
import { DEFAULT_CODE_VALUES, DEBUG_CODE_VALUES } from '@/core/constants';
import editorBorder from '@/assets/svg/editor-border.svg';
import oduLogo from '@/assets/svg/odu-logo.svg';
import downArrow from '@/assets/svg/down-arrow.svg';
import restart from '@/assets/svg/restart.svg';
import goldBar from '@/assets/images/gold-bar.png';
import crown from '@/assets/images/crown.png';
import bigBlueHead from '@/assets/images/big-blue-head.png';
import javascript from 'codemirror/mode/javascript/javascript';
import PizzaApp from './PizzaApp.vue';
import Marquee from './Marquee.vue';

const editors = {};

export default {
  inject: ['tracking'],
  components: {
    PizzaApp,
    editorBorder,
    restart,
    oduLogo,
    Marquee,
    downArrow,
  },
  props: {
    canAnimate: {
      type: Boolean,
      default: false,
    },
    entriesEnabled: {
      type: Boolean,
      default: false,
    },
    isSafari: {
      type: Boolean,
      default: false,
    },
    contestEnded: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    editor: null,
    debug: false,
    gameStarted: false,
    defaultValues: DEFAULT_CODE_VALUES,
    welcomeLoaded: false,
    welcomePlaying: false,
    goldBar,
    crown,
    bigBlueHead,
    values: {
      html: '',
      css: '',
      js: '',
    },
    activeTab: 'html',
    currentStep: null,
    valuesAtStep: {
      stepTwo: '',
      stepFour: '',
    },
    editors: {
      html: {
        mode: xml,
        modeString: 'xml',
        screenReaderLabel: 'HTML Tab',
      },
      css: {
        mode: css,
        modeString: 'css',
        screenReaderLabel: 'CSS Tab',
      },
      javascript: {
        mode: javascript,
        modeString: 'javascript',
        screenReaderLabel: 'Javascript Tab',
      },
    },
    screenByIndex: [
      'crust',
      'sauce',
      'cheese',
      'toppings',
      'bake',
    ],
    calloutCopy: 'The CS major at ODU has opportunities across several exciting fields like gaming, AI, web and app development, and even cybersecurity. Not only that, our alumni have gone on to lead lucrative careers in high demand jobs at companies like Google, Amazon, and Microsoft. So whatever your passion is, CS at ODU has got you covered. Even if it’s eating pizza!',
  }),
  computed: {
    dailyPizzasGone() {
      return this.remainingPizzas === 0;
    },
    allPizzasGone() {
      return this.totalEntries >= 200;
    },
  },
  mounted() {
    this.buildEditors();
  },
  methods: {
    buildEditors() {
      Object.keys(this.editors).forEach((editor) => {
        editors[editor] = new CodeMirror(this.$refs[editor], {
          lineNumbers: true,
          tabSize: 2,
          value: this.getDefaultForEditor(editor),
          mode: this.editors[editor].modeString,
          screenReaderLabel: this.editors[editor].screenReaderLabel,
          htmlMode: true,
          theme: 'monokai',
          lineWrapping: true,
        });
        this.values[editor] = this.getDefaultForEditor(editor);
        editors[editor].on('change', (cm) => {
          this.values[editor] = cm.doc.getValue();
        });
        this.$nextTick(() => {
          editors[editor].refresh();
        });
      });
    },
    getDefaultForEditor(editor) {
      let value = '';
      if (editor === 'html' && this.debug) {
        value = DEBUG_CODE_VALUES.html;
      }
      if (editor === 'javascript' && this.debug) {
        value = DEBUG_CODE_VALUES.javascript;
      }
      if (editor === 'css' && this.debug) {
        value = DEBUG_CODE_VALUES.css;
      }
      return value;
    },
    handleGameStart() {
      this.handleStepChange(0);
      this.gameStarted = true;
    },
    handleWelcomePlay(val) {
      this.welcomePlaying = val;
    },
    restartGame() {
      let newTab = this.activeTab;
      this.$refs.pizzaApp.showNextButton = false;
      if (this.currentStep === 0) {
        editors.html.getDoc().setValue(this.defaultValues.html.stepOne);
        newTab = 'html';
      }
      if (this.currentStep === 1) {
        editors.css.getDoc().setValue(this.defaultValues.css.stepTwo);
        newTab = 'css';
      }
      if (this.currentStep === 2) {
        editors.css.getDoc().setValue(`${this.valuesAtStep.stepTwo} ${this.defaultValues.css.stepThree}`);
        newTab = 'css';
      }
      if (this.currentStep === 3) {
        editors.javascript.getDoc().setValue(this.defaultValues.javascript.stepFour);
        newTab = 'js';
      }
      if (this.currentStep === 4) {
        editors.javascript.getDoc().setValue(`${this.valuesAtStep.stepFour} ${this.defaultValues.javascript.stepFive}`);
        newTab = 'js';
      }
      this.setActiveTab(newTab);
      this.$refs.pizzaApp.runCodeDisabled = false;
      this.tracking.trackEvent('e-game-restart', { step: this.screenByIndex[this.currentStep] });
    },
    setActiveTab(tab, isClick = false) {
      this.activeTab = tab;
      if (isClick) {
        this.tracking.trackEvent('e-game-tab-click', { tab });
      }
    },
    handleStepChange(newStep) {
      this.currentStep = newStep;
      let newTab = this.activeTab;
      if (!this.debug) {
        if (newStep === 0) {
          newTab = 'html';
          editors.html.getDoc().setValue(this.defaultValues.html.stepOne);
        }
        if (newStep === 1) {
          editors.css.getDoc().setValue(this.defaultValues.css.stepTwo);
          newTab = 'css';
        }
        if (newStep === 2) {
          newTab = 'css';
          this.valuesAtStep.stepTwo = this.values.css;
          editors.css.getDoc().setValue(`${this.values.css} ${this.defaultValues.css.stepThree}`);
        }
        if (newStep === 3) {
          editors.javascript.getDoc().setValue(this.defaultValues.javascript.stepFour);
          newTab = 'js';
        }
        if (newStep === 4) {
          this.valuesAtStep.stepFour = this.values.javascript;
          editors.javascript.getDoc().setValue(`${this.values.javascript} ${this.defaultValues.javascript.stepFive}`);
          newTab = 'js';
        }
      }

      this.setActiveTab(newTab);
    },
  },
};
</script>

<style lang="scss">
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/theme/monokai.css';
.codepen-container {
  padding: 60px 0;
  .odu-callout {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    &__crown {
      display: block;
      width: 42px;
      margin: 0 40px;
      img {
        width: 100%;
      }
    }
    &__inner {
      max-width: 1038px;
      position: relative;
      display: flex;
      text-decoration: none;
      cursor: pointer;
      transition: transform 300ms $easeOutQuad, box-shadow 300ms $easeOutQuad;
      &:hover {
        transform: translate(-4px, -4px);
        box-shadow: 10px 10px 0px 5px #020202;
      }
      &::before, &::after {
        content: '';
        width: 5px;
        top: 0px;
        height: 100%;
        position: absolute;
        pointer-events: none;
      }
      &::before {
        background: $brightBlue;
        z-index: 1;
        left: -5px;
      }
      &::after {
        background: $gamePink;
        z-index: 1;
        right: -5px;
      }
      &__background {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 3;
      }
      &__content {
        width: calc(100% - 88px);
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        padding: 5px 44px;
        align-items: center;
        position: relative;
        z-index: 5;
        &__head {
          flex-shrink: 0;
          display: block;
          height: 100%;
          max-width: 110px;
          transform: translateY(15px);
          img {
            display: block;
            width: 100%;
          }
        }
        &__copy {
          margin-left: 40px;
          color: #020202;
          h5 {
            font-family: $headlineFont;
            font-size: 12px;
            line-height: 25px;
            margin: 0;
            word-spacing: -7px;
            span {
              text-decoration: underline;
            }
          }
          p {
            font-size: 16px;
            margin: 0;
            line-height: 25px;
            letter-spacing: -0.5px
          }
        }
      }
    }
  }
  .editor {
    position: relative;
    width: 90%;
    max-width: 1500px;
    max-height: 850px;
    min-height: 750px;
    height: 90vh;
    display: flex;
    flex-direction: column;
    .CodeMirror * {
      font-size: 16px;
    }
    .cm-s-monokai.CodeMirror, .cm-s-monokai .CodeMirror-gutters {
      background: #231E1E;
    }
    &-container {
      padding: {
        top:  30px;
        bottom: 30px;
      }
      display: flex;
      justify-content: center;
      overflow: hidden;
    }
    &__border {
      position: absolute;
      width: calc(100% + 15px);
      height: calc(100% + 50px);
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      svg {
        height: 100%;
        width: 100%;
      }
    }
    &__header {
      background: black;
      height: 74px;
      position: relative;
      &__content {
        display: flex;
        align-items: flex-end;
        color: white;
        height: 100%;
        .spacer { width: 30%; }
        .odu-copy {
          display: flex;
          align-items: center;
          text-align: left;
          position: relative;
          font-family: $headlineFont;
          color: white;
          text-decoration: none;
          font-size: 8px;
          line-height: 15px;
          margin: {
            left: auto;
            bottom: 17px;
            right: 50px;
          }
          &:hover {
            .underline {
              .arrow {
                transform: rotate(-90deg) translate(-15%, 140%);
              }
            }
          }
          .underline {
            position: relative;
            &::before {
              content: '';
              position: absolute;
              bottom: -3px;
              height: 1px;
              width: 100%;
              background: $gameYellow;
              z-index: 10;
            }
            .arrow {
              position: absolute;
              width: 15px;
              right: -10px;
              left: auto;
              bottom: 0;
              transform: rotate(-90deg) translate(-15%, 100%);
              transition: transform 300ms $easeOutQuad;
            }
          }
          svg {
            width: 30px;
            position: absolute;
            left: -15px;
            bottom: 5px;
            transform: translateX(-100%);
          }
        }
      }
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #244370;
      }
      &__nav {
        position: absolute;
        z-index: 2;
        bottom: 0;
        margin: 0;
        padding: 0;
        margin-left: 10px;
        list-style: none;
        white-space: nowrap;
        display: flex;
        li {
          margin-right: 10px;
          &:first-child {
            a {
              border-top-left-radius: 2px;
              border-bottom-left-radius: 2px;
            }
          }
          &:last-child {
            a {
              border-top-right-radius: 2px;
              border-bottom-right-radius: 2px;
            }
          }
          a {
            cursor: pointer;
            display: block;
            font-family: $headlineFont;
            transition: background 200ms ease, box-shadow 200ms ease;
            text-decoration: none;
            padding: 10px 16px 10px 16px;
            letter-spacing: -2px;
            font-size: 15px;
            color: #bbbbbb;
            border: 1px solid #244370;
            border-bottom: none;
            background: #244370;
          }
          &.active {
            a {
              background: #231E1E;
              color: $gameYellow;
            }
          }
        }
      }
    }
    &__body {
      width: 100%;
      height: 100%;
      display: flex;
    }
    &__tabs {
      width: 30%;
      height: 100%;
      position: relative;
      .restart-button {
        position: absolute;
        z-index: 19;
        left: 20px;
        bottom: -5px;
        display: flex;
        align-items: center;
        font-family: $headlineFont;
        background: none;
        border: none;
        color: white;
        text-transform: uppercase;
        font-size: 9px;
        cursor: pointer;
        padding: 0;
        height: 30px;
        margin-bottom: 6px;
        pointer-events: none;
        opacity: 0;
        &.visible {
          opacity: 1;
          pointer-events: all;
        }
        &:focus {
          outline: none;
        }
        svg {
          width: 15px;
          margin-left: 5px;
        }
      }
      &__tab {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        pointer-events: none;
        &.active {
          opacity: 1;
          pointer-events: all;
        }
        .CodeMirror {
          height: 100%;
        }
      }
    }
  }
}
</style>
