import stepOneHeadline from '@/assets/images/step-one.png';
import stepTwoHeadline from '@/assets/images/step-two.png';
import stepThreeHeadline from '@/assets/images/step-three.png';
import stepFourHeadline from '@/assets/images/step-four.png';
import stepFiveHeadline from '@/assets/images/step-five.png';

export const STEPS = [
  {
    headline: stepOneHeadline,
    alt: 'Code your crust',
    stepCount: 'STEP 1/5',
    copy: [
      'What’s a pizza without the crust? Let’s start by using HTML, a computer language that sets up the structure of a web page, in this case our pizza.',
      'First, <span class="yellow">add the word “crust” to the &lt;div id&gt; on the left</span>. A &lt;div&gt; acts as a container for code kind of like a pizza box.',
      'Next, let’s <span class="yellow">assign a class to that crust</span>. Classes help style elements. Choose one of our crust styles: <span class="yellow">classic, chicago, wheat, neapolitan, or cauliflower.</span>',
    ],
    defs: {
      ID: '<b>IDs</b> are unique. They can be used to identify and style a single element.',
      class: 'A <b>class</b> is used to help style elements and can be used on more than one element.',
    },
    stepHint: 'Navigate to the HTML tab and create a new <b>&lt;div&gt;</b> tag with an ID of “crust”.',
  },
  {
    headline: stepTwoHeadline,
    alt: 'Style your sauce',
    stepCount: 'STEP 2/5',
    copy: [
      'OK, time to get saucy with CSS. CSS is used to define different styles like fonts or color.',
      'We’ll need a hex code to color our sauce. Hex codes are 6-digit representations of color. Each pair of digits scale from 00 to FF. The first two for red, the next for green, and the last for blue.',
      '<span class="yellow">Update “fill” in the CSS tab with a saucy hex code.</span> We know sauce is usually red (FF0000) but this is your pizza, you call the shots. Just don’t forget to start with a hashtag (#).',
    ],
    defs: {
      fill: 'Colors in CSS use hex codes, so your options are truly endless. For example, a darker red for BBQ sauce would look something like <b>#750037</b>. You can also type in typical colors too, like <b>“green”</b> for pesto.',
      block: 'No, this isn’t a block of cheese (we wish it was) – the <b>block property</b> actually means the HTML element takes up the full width of its parent element, starting on a new line.',
    },
    stepHint: 'If you’re using a hex code, make sure there’s a # before the 6 digits. Only change the purple text.',
  },
  {
    headline: stepThreeHeadline,
    alt: 'Cheese Please',
    stepCount: 'STEP 3/5',
    copy: [
      'Here’s an easy one. <span class="yellow">Change the cheese display from “none” to “block”</span>.',
      'No, not a block of cheese ;) A block element fills an entire line taking up the full width of its container.',
      'The “none” descriptor means the element is being hidden.',
    ],
    defs: {
      fill: 'Colors in CSS use hex codes, so your options are truly endless. For example, a darker red for BBQ sauce would look something like <b>#750037</b>. You can also type in typical colors too, like <b>“green”</b> for pesto.',
      block: 'No, this isn’t a block of cheese (we wish it was) – the <b>block property</b> actually means the HTML element takes up the full width of its parent element, starting on a new line.',
    },
    stepHint: 'If you’re using a hex code, make sure there’s a # before the 6 digits. Only change the purple text.',
  },
  {
    headline: stepFourHeadline,
    stepCount: 'STEP 4/5',
    alt: 'All the toppings',
    copy: [
      'Time for some tasty toppings using JavaScript. JavaScript can turn a website from static to dynamic. Each topping is created as a function, which is used to perform specific tasks.',
      'To add your toppings, <span class="yellow">change each of the function parameters to any number from 1 to 12</span>. If you just hate a certain topping, leave the parameter as 0',
      'Want more? We’ve got mushrooms, olives, and pineapples too. Just make sure to uncomment them by deleting the “//”. <span class="yellow">Don’t forget to add at least two different toppings!</span>',
    ],
    defs: {
      functions: 'A function is meant to do one thing and one thing only. We’re currently calling pepperoni. You wouldn’t call pepperoni and mushroom in the same function.',
      parameter: 'Parameters allow you to pass information or instructions into functions.',
      'another.number': 'The boss only allows up to 10 toppings per pizza. We know, lame.',
    },
    stepHint: 'Functions always start with a verb, Make sure each function starts with “add” and the first letter of the topping is capitalized. Don’t forget that semicolon.',
  },
  {
    headline: stepFiveHeadline,
    stepCount: 'STEP 5/5',
    alt: 'Check your order',
    copy: [
      'The countdown to PIZZA is on. You’re so close to finishing the last step!',
      'Just like a recipe, coders need to tell the computer the correct order of steps to follow. <span class="yellow">Rearrange the functions in the correct order to bake your pie.</span>',
      '<span class="yellow">Be sure to uncomment the last line of code.</span>',
    ],
    defs: {
      function: 'This function contains instructions used to create your pizza. Make sure it’s in the right order.',
      uncomment: 'In JavaScript, comments start with two slashes. Once you’re confident in your function order, remove the slashes from “//bakePizza();”',
    },
    stepHint: 'runTimer(); goes after addPizza();.',
  },
];

export const DEFAULT_CODE_VALUES = {
  html: {
    stepOne: '<!-- STEP 1: Update the id and class below -->\n<!-- Class Options: classic, chicago, wheat, neapolitan, cauliflower -->\n\n<div id="TYPE_HERE" class="TYPE_HERE">\n</div>',
  },
  css: {
    stepTwo: '/* STEP 2: change the fill color below. We’ve given you some choices to try out. */\n\n/* Pesto: #0E822B */\n/* BBQ: #7A0010 */\n/* Alfredo: #ECEDD1 */\n/* Tomato: #C92A2A */\n\n#sauce {\n fill: #FF0000; \n}',
    stepThree: '\n\n/* STEP 3: change the display property below */\n\n#cheese {\n display: none; \n}',
  },
  javascript: {
    stepFour: '// STEP 4: Change the function’s parameters below\n\naddPepperoni(0);\n\naddPeppers(0);\n\naddOnions(0);\n\n// addPineapple(0);\n\n// addMushrooms(0);\n\n// addOlives(0);',
    stepFive: '\n\n// STEP 5: Reorder the functions below then uncomment bakePizza();\n\nfunction bakePizza() {\n  addPizza();\n  preHeat();\n  removePizza();\n  runTimer();\n}\n\n// bakePizza();',
  },
};

export const DEBUG_CODE_VALUES = {
  html: '<div id="crust" class="classic"></div>',
  css: '#sauce {\n fill: #FF0000; \n} \n#cheese {\n display: block; \n}',
  javascript: 'addPepperoni(10);\n\naddPeppers(10);\n\naddOnions(10);\n\nfunction bakePizza() {\n  preHeat();\n  addPizza();\n  runTimer();\n  removePizza();\n}\n// bakePizza();',
};

export const SUCCESS_OPTIONS_BY_STEP = {
  one: {
    chicago: 'Mmm deep dish. Chicago’s fave.',
    wheat: 'A healthy whole wheat can’t be beat.',
    cauliflower: 'Cauliflower? More like caulipower.',
    classic: 'Can’t go wrong with a classic crust. Solid.',
    neapolitan: 'Ah, the Napoletana. I see you’re a purist.',
  },
  two: {
    pesto: 'Pesto-perfecto! Let’s keep going.',
    bbq: 'BB-Beautiful! Let’s keep going.',
    alfredo: 'Alfredo for the win!  Let’s keep going.',
    tomato: 'Looks delish! Let’s keep going.',
    default: 'Decided not to change the color, huh?',
    other: 'Looks good to me! Let’s keep going.',
  },
  three: 'Nailed It!!',
  four: [
    'NOW, THAT’S A BEAUTIFUL PIE. LAST STEP...',
    'MMMMMM LOVE IT. LAST STEP...',
    'LOOK AT ALL THOSE TOPPINGS! LAST STEP...',
  ],
};

export const ERROR_OPTIONS = [
  'Ugh, something’s wrong. Let’s try again.',
  'Err. Let’s give it another go.',
  'Not working yet but let’s keep at it!',
  'Hmmm. Don’t think that’s going to work.',
  'We’re getting close. Let’s try again.',
];

export const VALID_CRUST_CLASSES = ['cauliflower', 'wheat', 'classic', 'chicago', 'neapolitan'];
