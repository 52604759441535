<template>
  <div class="game-step" :class="{visible}">
    <div class="game-step__body">
      <div class="game-step__body__content">
        <div>
          <h3 class="step-count" v-html="step.stepCount" />
          <img :src="step.headline" class="headline" :alt="step.alt" />
          <p v-for="copy in step.copy" :key="copy" v-html="copy" />
        </div>
        <div class="game-step__body__step-buttons">
          <button
            class="step-button"
            @click="$emit('check-step')"
            :class="{ disabled: runCodeDisabled || !visible }"
          >
            &lt;run_my_Code&gt;
          </button>
          <button
            class="step-button step-button--next"
            @click="$emit('increment-step')"
            :class="{
              disabled: runCodeDisabled || !visible,
              visible: showNextButton && visible,
            }"
          >
            &lt;next_step&gt;
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Object,
      required: true,
    },
    visible: {
      type: Boolean,
      required: true,
    },
    showNextButton: {
      type: Boolean,
      required: true,
    },
    stepValid: {
      type: Boolean,
      required: true,
    },
    runCodeDisabled: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.game-step {
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 90%;
  max-width: 425px;
  height: 80%;
  color: white;
  opacity: 0;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  transition: opacity 300ms $easeOutQuad;
  &.visible {
    transition: opacity 300ms $easeOutQuad 300ms;
    opacity: 1;
    pointer-events: all;
  }
  &__body {
    background: $darkBlue;
    position: relative;
    height: calc(100% - 49px);
    min-height: 400px;
    display: flex;
    z-index: 5;
    padding: 15px 0 20px;
    border: 7px solid $gameYellow;
    box-shadow: 18px 13px 0px 0px #112A45;
    &::before, &::after {
      content: '';
      width: 5px;
      top: -7px;
      height: calc(100% + 14px);
      position: absolute;
      pointer-events: none;
    }
    &::before {
      background: $brightBlue;
      z-index: 1;
      left: -12px;
    }
    &::after {
      background: $gamePink;
      z-index: 1;
      right: -12px;
    }
    &__content {
      padding: 0 40px;
      max-height: 500px;
      overflow-y: auto;
      height: calc(100% - 70px);
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      @media screen and (max-width: 1440px) {
        padding: 0 20px;
      }
      @media screen and (max-width: 1300px) {
        height: calc(100% - 121px);
      }
    }
    .step-count {
      font-family: $headlineFont;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 32px;
      margin: 0;
      margin-top: 18px;
    }
    p {
      margin-top: 0;
      margin-bottom: 20px;
      font-size: 14px;
      line-height: 21px;
      &:last-child {
        margin-bottom: 0;
      }
      .yellow {
        color: $gameYellow;
      }
    }
    .headline {
      margin-bottom: 32px;
      width: 90%;
      max-width: 295px;
    }
    .check-button {
      align-self: flex-end;
      border: 3px solid $gameYellow;
      background: none;
      color: $gameYellow;

      text-transform: uppercase;
      display: inline-block;
    }

    &__step-buttons {
      display: flex;
      width: 100%;
      margin-top: 20px;
      position: absolute;
      bottom: 35px;
      left: 40px;
      .step-button {
        cursor: pointer;
        font-family: $headlineFont;
        font-size: 10px;
        color: $darkBlue;
        text-align: center;
        padding: 15px;
        background: $gameYellow;
        text-transform: uppercase;
        border: 2px solid white;
        transition:
          border-color 300ms $easeOutQuad,
          background 300ms $easeOutQuad,
          opacity 300ms $easeOutQuad;
        &--next {
          opacity: 0;
          pointer-events: none;
          margin-left: 15px;
          &.visible {
            opacity: 1;
            pointer-events: all;
          }
        }
        &.disabled {
          color: #717171;
          border-color: #717171;
          background: #244370;
          pointer-events: none;
        }
        &:hover {
          background: white;
          border-color: $gameYellow;
        }
        &:focus {
          outline: none;
        }
      }
      @media screen and (max-width: 1440px) {
        left: 20px;
      }
      @media screen and (max-width: 1300px) {
        flex-direction: column;
        width: calc(100% - 40px);
        bottom: 20px;
        .step-button {
          &--next {
            margin: 10px 0 0 0;
          }
        }
      }
    }
  }
}
</style>
