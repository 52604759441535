<template>
  <div class="form-input oneField field-container-D" :id="`${id}-D`">
    <div class="form-input__labels">
      <label :for="id" :id="`${id}-L`">{{label}}</label>
      <p class="required">
        *{{requiredCopy}}
      </p>
    </div>
    <div class="form-input__wrapper">
      <input
        :name="id"
        :id="id"
        class="form-input__input"
        :class="{ required }"
        v-model="input"
        :placeholder="placeholder"
        :aria-required="required"
        :type="type"
      />
    </div>
    <transition name="fade" mode="out-in">
      <p class="form-input__error" :key="errorCopy" v-if="showError">{{errorCopy}}</p>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'form-input',
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    input: '',
  }),
  computed: {
    requiredCopy() {
      return this.required ? 'required' : 'optional';
    },
    showError() {
      return this.type === 'tel' || this.error;
    },
    errorCopy() {
      let copy = '';
      if (this.type === 'tel') {
        if (this.error) copy = 'THIS DOES NOT APPEAR TO BE A VALID NUMBER';
        else copy = 'WE SEND OCCASIONAL TEXT MESSAGES';
      }
      if (this.error === 'invalid' && this.label === 'E-mail') copy = 'THIS DOES NOT APPEAR TO BE A VALID EMAIL';
      if (this.error === 'required') copy = 'THIS FIELD IS REQUIRED';
      return copy;
    },
  },
  watch: {
    input(val) {
      this.$emit('input-change', val);
    },
  },
};
</script>

<style lang="scss">
.form-input {
  display: flex;
  flex-direction: column;
  position: relative;
  &__labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
    label, .required {
      font-family: $headlineFont;
      color: #FBC504;
      font-size: 8px;
      margin: 0 0 10px 0;
      text-transform: uppercase;
    }
    .required {
      font-size: 5px;
    }
  }
  &__error {
    color: white;
    position: absolute;
    bottom: -20px;
    right: 0;
    font-family: $headlineFont;
    font-size: 5px;
  }
  &__wrapper {
    position: relative;
    padding: 12px;
    background: #FFFFFF;
    &::after {
      content: '';
      position: absolute;
      background: rgba(196, 196, 196, 0.25);
      width: 100%;
      height: 50%;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
  &__input {
    position: relative;
    z-index: 2;
    background: #E2E2E2;
    color: #17385C;
    width: 100%;
    box-sizing: border-box;
    border: none;
    padding: 8px 10px;
    font-family: $bodyFont;
    font-size: 10px;
    text-transform: uppercase;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 27.5px #E2E2E2 inset !important;
      -webkit-text-fill-color: #17385C !important;
    }
    &:focus {
      outline: none;
    }
  }
}
</style>
