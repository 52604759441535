<template>
  <div class="game-steps">
    <GameStep
      v-for="(step, i) in steps"
      :key="step.headline"
      :step="step"
      :visible="currentStep === i"
      :index="i"
      :runCodeDisabled="runCodeDisabled"
      :showNextButton="showNextButton"
      :stepValid="stepValid"
      @check-step="$emit('check-step', true)"
      @increment-step="$emit('increment-step')"
    />
  </div>
</template>

<script>
import { STEPS } from '@/core/constants';
import GameStep from './GameStep.vue';

export default {
  components: {
    GameStep,
  },
  props: {
    currentStep: {
      required: true,
    },
    showNextButton: {
      type: Boolean,
      default: false,
    },
    stepValid: {
      type: Boolean,
      default: false,
    },
    runCodeDisabled: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    steps: STEPS,
  }),
};
</script>

<style lang="scss">
.game-steps {
  position: relative;
  width: 50%;
  height: 100%;
  z-index: 10;
  padding-right: 20px;
}
</style>
