<template>
  <div class="landing" id="landing">
    <div class="landing__top" :class="{ small: smallDevice }">
      <div class="small-copy" v-if="smallDevice && !isWonPage">
        <p>THIS TASTY EXPERIENCE IS DESKTOP ONLY</p>
      </div>
      <div v-if="isWonPage" class="landing__share">
        <img :src="shareAsset" class="landing__share__image" alt="share image">
        <lionShadow class="shadow" />
      </div>
      <div
        class="landing__lottie landing__lottie--lion"
        :class="{ small: smallDevice }" v-if="!isWonPage"
      >
        <div class="landing__lottie__bubble">
          <bubble />
          <p v-if="contestEnded">GAME<br>OVER</p>
          <p v-else>WRITE CODE.<br>GET PIZZA!</p>
        </div>
        <img class="lottie" :src="bigBlue" @load="lottiesLoaded += 1;" alt="odu mascot" />
        <img :src="bigBlueLogo" class="big-blue-logo" alt="odu mascot logo" />
        <lionShadow class="shadow" />
      </div>
      <div
        class="landing__lottie landing__lottie--logo"
        :class="{ small: smallDevice }"
        v-if="!isWonPage"
      >
        <img :src="logo" @load="lottiesLoaded += 1;" alt="odu logo">
        <powered class="powered" />
        <logoShadow class="shadow" />
      </div>
      <div class="back-buttons" v-if="isWonPage">
        <router-link class="back-link" to="/" @click="tracking.trackEvent('e-winner-play-again')">
          &lt;play_again&gt;
        </router-link>
        <a class="back-link" href="https://odu.edu" target="_blank" @click="tracking.trackEvent('e-winner-odu-cs')">
        &lt;ODU/COMPSCI&gt;
        </a>
      </div>
      <div class="boxes" v-if="!isWonPage">
        <div class="boxes__left">
          <p class="side-title" v-if="!contestEnded">Win pizza for your VA High School:</p>
          <div class="box-container" v-if="!contestEnded">
            <div class="box">
              <span class="box__icon">
                <div class="icon-box">
                  <check class="box__icon__icon" />
                </div>
              </span>
              <p>NO CODING EXPERIENCE<br>REQUIRED</p>
              <heroBox class="box__background" />
            </div>
            <!-- <div class="box">
              <span class="box__icon">
                <div class="icon-box">
                  <check class="box__icon__icon check" />
                </div>
              </span>
              <p>ANYONE<br>CAN PLAY</p>
              <heroBox class="box__background" />
            </div>
            <div class="box">
              <span class="box__icon">
                <div class="icon-box">
                  <check class="box__icon__icon check" />
                </div>
              </span>
              <p>VIGINIA HIGH SCHOOL<br>STUDENT TO WIN</p>
              <heroBox class="box__background" />
            </div> -->
          </div>
        </div>
        <div class="boxes__right">
          <div v-if="!contestEnded">
            <p class="side-title">CONTEST DAYS LEFT:</p>
            <div class="box-container">
              <div class="box">
                <h3>{{remainingPizzasCopy}}</h3>
                <heroBox class="box__background" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="landing__top__pizzas landing__top__pizzas--left">
        <div class="pizza"><pizzaFrontLeft /></div>
        <div class="pizza pizza--middle"><pizzaMiddleLeft /></div>
        <div class="pizza pizza--back"><pizzaBackLeft /></div>
      </div>
      <div class="landing__top__pizzas landing__top__pizzas--right">
        <div class="pizza"><pizzaFrontRight /></div>
        <div class="pizza pizza--middle"><pizzaMiddleRight /></div>
        <div class="pizza pizza--back"><pizzaBackRight /></div>
      </div>
      <div class="sky-container" :class="{ canAnimate }">
        <stars class="sky-container__stars" ref="stars" />
        <cloudOne class="sky-container__cloud sky-container__cloud--one" />
        <cloudTwo class="sky-container__cloud sky-container__cloud--two" />
        <cloudThree class="sky-container__cloud sky-container__cloud--three" />
      </div>
    </div>
    <div class="landing__bottom" :class="{ small: smallDevice }">
      <floor class="landing__bottom__floor" />
      <border class="landing__bottom__border" />
      <div class="landing__bottom__copy">
       <div class="content">
          <p v-if="!isWonPage && !contestEnded">
            Curious about code? Curious about free pizza? Either way, you're in the right place!
            Try writing some simple code to build a virtual pie and we’ll
            give you the chance to win a pizza party for your high school.
          </p>
          <p v-if="!isWonPage && contestEnded">
            We’re NO LONGER GIVING AWAY FREE PIES. But if you’re curious about code you’re
            in the right place! Try writing some simple code to build your very own
            virtual pizza. Who knows, you might just uncover a new SKILL.
          </p>
          <div class="winner-copy" v-if="isWonPage">
            <ul class="winner-copy__share__list">
              <p class="winner-copy__share__copy">Share:</p>
              <li class="winner-copy__share__list__item download">
                <a
                  :href="shareAsset"
                  download="share.png"
                  @click="handleShareClick('instagram')"
                  aria-label="download"
                >
                  <download />
                </a>
              </li>
              <li class="winner-copy__share__list__item">
                <a
                  :href="shareLinks.facebook"
                  target="_blank"
                  @click="handleShareClick('facebook')"
                  aria-label="share on facebook"
                >
                  <facebook />
                </a>
              </li>
              <li class="winner-copy__share__list__item">
                <a
                  :href="shareLinks.twitter"
                  target="_blank"
                  @click="handleShareClick('twitter')"
                  aria-label="share on twitter"
                >
                  <twitter />
                </a>
              </li>
            </ul>
            <p>{{wonCopy}}</p>
            <p>
              Looks like coding might be in your future.
              If you’re curious about Computer Science, contact your advisor for more info.
            </p>
            <p>
              Now, let’s show off your new skills.<br>
              Maybe your friends will wanna pizza the action.
            </p>
          </div>
          <img :src="welcome" class="welcome" v-if="!isWonPage" alt="welcome to code for pizza" />
          <button
            class="scroll-arrow"
            @click="scrollToGame"
            @mousedown="preventFocus"
            aria-label="scroll button"
            v-if="!isWonPage"
          >
            <downArrow />
          </button>
       </div>
      </div>
      <div class="small-footer" v-if="smallDevice && !isWonPage">
        <ul>
          <li>
            <span class="copyright">Copyright © Old Dominion University</span>
            <span>11/25/20</span>
          </li>
          <li>
            <a href="https://google.com">Privacy</a>
          </li>
          <li>
            <a href="https://google.com">Accessibility</a>
          </li>
          <li>
            <a href="https://google.com">Consumer Information</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
// import * as lottie from 'lottie-web';
import floor from '@/assets/svg/floor.svg';
import powered from '@/assets/svg/powered.svg';
import logoShadow from '@/assets/svg/logo-shadow.svg';
import lionShadow from '@/assets/svg/lion-shadow.svg';
import border from '@/assets/svg/hero-border.svg';
import bubble from '@/assets/svg/bubble.svg';
import welcome from '@/assets/images/welcome.png';
import downArrow from '@/assets/svg/down-arrow.svg';
import pizzaFrontLeft from '@/assets/svg/pizza-front-left.svg';
import pizzaMiddleLeft from '@/assets/svg/pizza-middle-left.svg';
import pizzaBackLeft from '@/assets/svg/pizza-back-left.svg';
import pizzaFrontRight from '@/assets/svg/pizza-front-right.svg';
import pizzaMiddleRight from '@/assets/svg/pizza-middle-right.svg';
import pizzaBackRight from '@/assets/svg/pizza-back-right.svg';
import heroBox from '@/assets/svg/hero-box.svg';
import check from '@/assets/svg/check.svg';
// import cross from '@/assets/svg/cross.svg';
import stars from '@/assets/svg/stars.svg';
import cloudOne from '@/assets/svg/cloud-one.svg';
import cloudTwo from '@/assets/svg/cloud-two.svg';
import cloudThree from '@/assets/svg/cloud-three.svg';
import facebook from '@/assets/svg/facebook.svg';
import twitter from '@/assets/svg/twitter.svg';
import download from '@/assets/svg/download.svg';
import bigBlueLogo from '@/assets/images/big-blue-logo.png';
import bigBlue from '@/assets/images/big-blue.gif';
import shareAsset from '@/assets/images/winner-share.png';

import { preventFocus } from '@/core/utils';

// import bigBlue from '@/assets/lottie/big-blue.json';
import logo from '@/assets/images/hero-logo.gif';

export default {
  inject: ['tracking'],
  components: {
    floor,
    powered,
    logoShadow,
    lionShadow,
    border,
    downArrow,
    pizzaFrontLeft,
    pizzaMiddleLeft,
    pizzaBackLeft,
    pizzaFrontRight,
    pizzaMiddleRight,
    pizzaBackRight,
    heroBox,
    check,
    // cross,
    stars,
    cloudOne,
    cloudTwo,
    cloudThree,
    bubble,
    facebook,
    twitter,
    download,
  },
  data: () => ({
    lion: null,
    preventFocus,
    // audio,
    bigBlueLogo,
    bigBlue,
    logo,
    welcome,
    shareAsset,
    lottiesLoaded: 0,
    renderMusic: false,
    musicPlaying: false,
  }),
  props: {
    canAnimate: {
      type: Boolean,
      default: false,
    },
    isSafari: {
      type: Boolean,
      default: false,
    },
    daysRemaining: {
      type: Number,
      default: 0,
    },
    contestStarted: {
      type: Boolean,
      default: false,
    },
    contestEnded: {
      type: Boolean,
      default: false,
    },
    smallDevice: {
      type: Boolean,
      default: false,
    },
    isWonPage: {
      type: Boolean,
      default: false,
    },
    shareLinks: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    remainingPizzasCopy() {
      if (!this.contestStarted) return '000';
      return `0${this.daysRemaining}`;
    },
    wonCopy() {
      const { fname } = this.$route.query;
      if (fname) return `Great Job ${fname}!`;
      return 'Great Job!';
    },
  },
  watch: {
    lottiesLoaded(val) {
      if (val === 2) {
        this.$emit('loaded');
      }
    },
  },
  mounted() {
    const starPaths = Array.from(this.$refs.stars.$el.querySelectorAll('path'));
    starPaths.forEach((star) => {
      // eslint-disable-next-line
      star.style.animationDelay = `${Math.random()}s`;
    });

    // document.addEventListener('click', this.handleRenderMusic);
  },
  beforeUnmount() {
    // document.removeEventListener('click', this.handleRenderMusic);
  },
  methods: {
    handleRenderMusic() {
      this.renderMusic = true;
      document.removeEventListener('click', this.handleRenderMusic);
    },
    handleShareClick(share) {
      this.tracking.trackEvent('e-winner-share', { socialPlatform: share });
    },
    handleBoomboxClick() {
      if (this.musicPlaying) {
        this.$refs.music.pause();
      } else if (this.$refs.music) {
        this.$refs.music.play();
      }
    },
    scrollToGame() {
      this.tracking.trackEvent('e-hero-scroll-click');
      const el = document.querySelector('#game-window');
      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = el.getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top - 50;
      window.scrollTo({
        top: offset,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss">
.landing {
  min-height: 850px;
  width: 100%;
  overflow: hidden;
  &__lottie {
    position: absolute;
    z-index: 2;
    .shadow {
      position: absolute;
    }
    &__bubble {
      position: absolute;
      width: 140px;
      top: 22%;
      left: 23%;
      transform: translate(-60%, -105%);
      z-index: 10;
      p {
        width: 80%;
        text-align: center;
        position: absolute;
        top: 30%;
        left: 15px;
        transform: translateY(-50%);
        font-family: $headlineFont;
        font-size: 10px;
        line-height: 16px;
        text-transform: uppercase;
      }
    }
    &--lion {
      width: 15%;
      left: 28%;
      bottom: 0;
      transform: translate(-50%, 30%);
      .lottie {
        display: block;
        width: 100%;
      }
      &.small {
        display: none;
      }
      .shadow {
        width: 100%;
        left: 60%;
        bottom: -1%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
      .big-blue-logo {
        position: absolute;
        top: 45%;
        left: 63.5%;
        width: 17%;
        transform: translate(-50%, -50%);
      }
    }
    &--logo {
      width: 30%;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 15%);
      img {
        display: block;
        width: 100%;
      }
      &.small {
        width: 55%;
        max-width: 400px;
        bottom: auto;
        top: 50%;
        transform: translate(-50%, -50%);
        .shadow {
          display: none;
        }
      }
      .shadow {
        width: 70%;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
      }
      .powered {
        position: absolute;
        left: 49.5%;
        top: 21%;
        width: 52%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &__top, &__bottom {
    height: 50vh;
    width: 100%;
    position: relative;
  }
  &__top {
    width: 100%;
    height: 50vh;
    min-height: 450px;
    @include bpXLarge {
      min-height: 500px;
    }
    @media screen and (max-height: 850px) {
      min-height: 400px;
    }
    background: linear-gradient(0deg, #52ABC2 -16.12%, #002F62 97.23%);
    &.small {
      height: 60vh;
      min-height: 0;
      @media screen and (max-width: 700px) {
        height: 450px;
      }
      .boxes {
        display: none;
      }
    }
    .small-copy {
      background: $gameYellow;
      position: absolute;
      color: white;
      font-family: $bodyFont;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      color: #17385C;
      max-width: 290px;
      width: 100%;
      left: 50%;
      bottom: 0%;
      transform: translate(-50%, -25%);
      border: 2px solid #194473;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 10px 0;
      border-radius: 9px;
      @media screen and (max-width: 600px) {
        bottom: 8%;
      }
      @media screen and (max-width: 500px) {
        bottom: 8%;
      }
    }
    .boxes {
      position: absolute;
      display: flex;
      justify-content: space-between;
      top: 0;
      left: 0;
      width: 100%;
      padding-top: 32px;
      z-index: 10;
      p {
        position: relative;
        z-index: 2;
        font-family: $headlineFont;
        color: white;
        width: 100%;
        font-size: 10px;

        line-height: 17px;
        &.side-title {
          margin-bottom: 10px;
          font-size: 7px;
          line-height: 8px;
          text-transform: uppercase;
        }
      }
      &__left {
        display: flex;
        flex-direction: column;
        padding-left: 45px;
        .box-container {
          display: flex;
          .box {
            margin-right: 16px;
            display: flex;
            p {
              margin-left: 20px;
              line-height: 17px;
            }
          }
        }
      }
      &__right {
        padding-right: 45px;
        display: flex;
        align-items: flex-end;
        .music {
          position: relative;
          display: flex;
          align-items: flex-end;
          margin-right: 25px;
          &.playing {
            .music {
              &__notes {
                opacity: 1;
                &__note {
                  animation-play-state: running;
                }
              }
            }
          }
          &__boombox {
            width: 55px;
            cursor: pointer;
          }
          &__notes {
            opacity: 0;
            transition: opacity 300ms $easeOutQuad;
            &__note {
              width: 10px;
              position: absolute;
              top: 0;
              left: -15px;
              animation: noteAnimate 2.5s linear 0s infinite;
              animation-play-state: paused;
              opacity: 0;
              @for $i from 1 through 3 {
                &:nth-child(#{$i}) {
                  animation-delay: calc(#{$i - 1} * (2.5s / 3));
                }
              }
            }
          }
        }
        .box-container {
          .box {
            padding: 15px 10px
          }
        }
        h3 {
          position: relative;
          z-index: 2;
          font-family: $headlineFont;
          font-size: 24px;
          color: white;
          line-height: 17px;
          letter-spacing: 1px;
          margin: 0;
        }
      }
      .box {
        position: relative;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
        box-shadow: 7px 9px 0px 0px #0F3054;
        &__icon {
          position: relative;
          z-index: 2;
          background: white;
          .icon-box {
            display: block;
            justify-content: center;
            align-items: center;
            border: 2px solid black;
            width: 25px;
            height: 25px;
          }
          &__icon {
            position: absolute;
            width: 120%;
            height: 120%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            &.check {
              width: 160%;
              left: 56%;
            }
          }
        }
        &__background {
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }
    }
    &__pizzas {
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 25%;
      overflow: hidden;
      height: 100%;
      display: flex;
      .pizza {
        position: absolute;
        width: 100%;
        bottom: 0;
        z-index: 3;
        display: flex;
        align-items: flex-end;
        svg {
          width: 100%;
        }
        &--middle {
          z-index: 2;
          width: 60%;
        }
        &--back {
          z-index: 1;
          width: 35%;
        }
      }
      &--right {
        right: 0;
        left: auto;
        .pizza {
          right: 0;
          transform: translateX(50%);
          &--middle {
            transform: translateX(-30%);
          }
          &--back {
            right: 50%;
            transform: translateX(-30%);
          }
        }
      }
      &--left {
        .pizza {
          left: 0;
          transform: translateX(-50%);
          &--middle {
            transform: translateX(30%);
          }
          &--back {
            left: 50%;
            transform: translateX(30%);
          }
        }
      }
    }
    .sky-container {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      &__stars {
        position: absolute;
        width: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 45%;
        z-index: 1;
      }
      &__cloud {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 100%;
        animation-iteration-count: infinite;
        animation-fill-mode: normal;
        animation-timing-function: linear;
        &--one {
          top: 30%;
          width: 30%;
          max-width: 450px;
          animation-duration: 28s;
          animation-delay: 10.5s;
        }
        &--two {
          max-width: 344px;
          top: 20%;
          width: 25%;
          animation-duration: 36s;
          animation-delay: -5.5s;
        }
        &--three {
          top: 50%;
          width: 30%;
          max-width: 450px;
          animation-duration: 31s;
          animation-delay: -10.5s;
        }
      }
      &.canAnimate {
        .sky-container {
          &__stars {
            path {
              animation: glitter 3.5s linear 0s infinite normal;
            }
          }
          &__cloud { animation-name: moveCloud; }
        }
      }
    }
  }
  &__bottom {
    overflow: hidden;
    min-height: 475px;
    @media screen and (max-height: 750px) {
      min-height: 400px;
    }
    .small-footer {
      position: absolute;
      background: rgb(17, 42, 69);
      bottom: 0;
      width: 100%;
      display: flex;
      color: white;
      justify-content: center;
      align-items: center;
      height: 60px;
      ul {
        display: flex;
        padding: 0;
        list-style: none;
        align-items: center;
        margin: 0;
        @media screen and (max-width: 735px) {
          flex-wrap: wrap;
          max-width: 360px;
          li {
            &:first-child {
              width: 100%;
              &:after { display: none; }
            }
          }
        }
        li {
          padding: 0 15px;
          color: white;
          font-family: $bodyFont;
          font-size: 12px;
          line-height: 20px;
          position: relative;
          .copyright {
            display: inline-block;
            padding-right: 30px;
            position: relative;
            &:after {
              content: '';
              width: 6px;
              height: 6px;
              position: absolute;
              right: 15px;
              top: 50%;
              transform: translate(50%, -50%);
              background: white;
              border-radius: 50%;
            }
          }
          &:after {
            content: '';
            width: 6px;
            height: 6px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(50%, -50%);
            background: white;
            border-radius: 50%;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
          a {
            color: white;
          }
        }
      }
    }
    &.small {
      @media screen and (max-width: 700px) {
        height: 400px;
      }
      .landing__bottom {
        &__border {
          bottom: 59px;
        }
        &__copy {
          bottom: 55%;
          width: 80%;
          img, .scroll-arrow {
            display: none;
          }
        }
      }

    }
    &__copy {
      position: absolute;
      bottom: 45%;
      width: 90%;
      left: 50%;
      transform: translate(-50%, 50%);
      z-index: 3;
      padding: 30px;
      .content {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .welcome {
          width: 425px;
          margin-top: 20px;
        }
        .scroll-arrow {
          width: 46px;
          margin-top: 50px;
          background: none;
          border: none;
          animation: bounce 3s infinite $easeOutQuad;
          cursor: pointer;
          svg {
            width: 100%;
          }
        }
      }
      p {
        max-width: 1030px;
        color: white;
        font-family: $headlineFont;
        font-size: 12px;
        line-height: 22px;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0px 3px 0px rgba(0, 0, 0, 1);
        .asterisk {
          position: relative;
          display: inline-block;
          margin-right: 5px;
          &:after {
            content: '*';
            font-size: 9px;
            height: 10px;
            top: 0;
            right: 0;
            position: absolute;
            transform: translate(110%, -50%);
          }
        }
      }
      .fresh-out {
        color: #FBC504;
        background: #17385C;
        border: 2px solid rgba(135, 198, 255, 0.52);
        max-width: 100%;
        padding: 10px 20px;
      }
    }
    &__floor {
      width: 100%;
      height: 100%;
    }
    &__border {
      position: absolute;
      bottom: -2px;
      width: 100%;
      z-index: 2;
      left: 0;
    }
  }
}
</style>
