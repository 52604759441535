<template>
  <div class="marquee animate">
    <div class="marquee__container">
      <div class="marquee__container__marquee marquee__container__marquee--white">
        <span>WRITE CODE. GET PIZZA.</span>
        <span>WRITE CODE. GET PIZZA.</span>
        <span>WRITE CODE. GET PIZZA.</span>
        <span>WRITE CODE. GET PIZZA.</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'marquee',
  props: {
    canAnimate: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
$marqueeWidth: 40vw;
$offset: 20vw;
$moveInitial: calc(-25% + $offset);
$moveFinal: calc(-50% + $offset);
.marquee {
  position: relative;
  margin-bottom: 60px;
  height: 90px;
  &.animate {
    .marquee {
      &__container {
        &__marquee {
          span {
            // animation-play-state: running;
          }
        }
      }
    }
  }
  &__container {
    width: 100%;
    top: 0;
    position: absolute;
    z-index: 10;
    overflow: hidden;
    &__marquee {
      width: fit-content;
      display: flex;
      font-size: 1.4vw;
      line-height: 1.2;
      letter-spacing: 1.5px;
      font-family: $headlineFont;
      overflow: hidden;
      span {
        overflow: visible;
        position: relative;
        display: block;
        white-space: nowrap;
        color: white;
        width: $marqueeWidth;
        flex-shrink: 0;
        will-change: transform;
        text-shadow: 0px 5px 0px rgba(0, 0, 0, 1);
        text-align: center;
        animation: marquee 4.5s linear infinite;
        &::before, &::after {
          content: '';
          position: absolute;
          width: 4px;
          height: 100%;
          background: $gamePink;
          right: 0;
          top: -5px;
          transform: translateX(50%);
          z-index: 2;
        }
        &::after {
          background: black;
          top: 0px;
          z-index: 1;
        }
      }
    }
  }
}
@keyframes marquee {
    0% { transform: translateX(0); }
    100% { transform: translateX(-100%); }
}
</style>
