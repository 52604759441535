import { createApp } from 'vue';
import App from './App.vue';
import Tracking from './core/tracking/index';
import router from './router';

const tracking = new Tracking();
tracking.init();

const app = createApp(App).use(router);
app.provide('tracking', tracking);
app.mount('#app');
