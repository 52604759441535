<template>
  <div class="pizza-app" id="pizza-app">
    <button
      style="position: fixed; top: 10px; right: 10px"
      @click="$refs.pizzaGuy.animPizzaGuyIn();"
      v-if="false"
    >
      debug anims
    </button>
    <stars class="pizza-app__stars" />
    <border class="pizza-app__border" />
    <transition name="fade">
      <WelcomeScreen
        @start-game="handleStartGame"
        :canAnimate="canAnimate"
        :contestEnded="contestEnded"
        v-if="showWelcome"
        @welcome-play="handleWelcomePlay"
        @welcome-loaded="$emit('welcome-loaded')"
        v-show="!debug"
      />
    </transition>
    <GameSteps
      ref="steps"
      :currentStep="currentStep"
      :runCodeDisabled="runCodeDisabled"
      :showNextButton="showNextButton"
      :stepValid="stepValid"
      @increment-step="handleIncremementStep"
      @check-step="handleUpdateCode"
    />
    <div class="pizza-container">
      <iframe
        id="iframe"
        ref="iframe"
        title="Pizza"
      />
      <steam class="pizza-steam" />
    </div>
    <PizzaGuy
      ref="pizzaGuy"
      :copy="pizzaGuyCopy"
      :isSafari="isSafari"
      @pizza-guy-complete="handlePizzaGuyComplete"
    />
    <EndingAnimation
      :animationData="gameWin"
      :visible="showGameWin"
      :isSafari="isSafari"
      @final-anim-complete="handleFinalAnimComplete"
    />
    <FinalScreen
      :visible="showFinalScreen"
      :contestEnded="contestEnded"
      @goToContact="handleGoToContact"
    />
    <ContactForm
      :visible="showContactForm"
      :selectedSchool="selectedSchool"
      @backToFinal="handleBackToFinal"
    />
  </div>
</template>

<script>
import gsap, { Power2 } from 'gsap';
import border from '@/assets/svg/border.svg';
import stars from '@/assets/svg/stars.svg';
import steam from '@/assets/svg/steam.svg';
import gameWinBody from '@/assets/lottie/win-body.json';
import gameWinType from '@/assets/lottie/win-type.json';
import { buildScriptTag, buildScriptSrcTag } from '@/core/utils';
import {
  SUCCESS_OPTIONS_BY_STEP,
  VALID_CRUST_CLASSES,
  ERROR_OPTIONS,
} from '@/core/constants';
import EndingAnimation from './EndingAnimation.vue';
import GameSteps from './GameSteps.vue';
import WelcomeScreen from './WelcomeScreen.vue';
import PizzaGuy from './PizzaGuy.vue';
import FinalScreen from './FinalScreen.vue';
import ContactForm from './ContactForm.vue';

export default {
  name: 'pizza-app',
  inject: ['tracking'],
  components: {
    border,
    GameSteps,
    WelcomeScreen,
    PizzaGuy,
    EndingAnimation,
    steam,
    stars,
    FinalScreen,
    ContactForm,
  },
  props: {
    codeValues: {
      type: Object,
      required: true,
    },
    canAnimate: {
      type: Boolean,
      default: false,
    },
    contestEnded: {
      type: Boolean,
      required: true,
    },
    isSafari: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    border,
    gameWin: {
      type: gameWinType,
      body: gameWinBody,
    },
    showWelcome: true,
    debug: false,
    showGameWin: false,
    showGameError: false,
    showNextButton: false,
    currentStep: null,
    stepValid: false,
    currentHash: '183d8d18573af7e0a20f',
    pizzaGuyCopy: '',
    validCrustClasses: VALID_CRUST_CLASSES,
    successCopy: SUCCESS_OPTIONS_BY_STEP,
    runCodeClick: false,
    gameRestart: false,
    runCodeDisabled: false,
    showFinalScreen: false,
    showContactForm: false,
    toppingsCount: 0,
    steamComplete: 0,
    userFunctionOrder: [],
    selectedSchool: null,
    correctFunctionOrder: ['preHeat', 'addPizza', 'runTimer', 'removePizza'],
    fills: {
      '0E822B': 'pesto',
      '7A0010': 'bbq',
      ECEDD1: 'alfredo',
      C92A2A: 'tomato',
      FF0000: 'default',
    },
    screenByIndex: [
      'crust',
      'sauce',
      'cheese',
      'toppings',
      'bake',
    ],
    attemptsByStep: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    },
    timeByStep: {
      0: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    },
  }),
  computed: {
    javscriptSrc() {
      return `https://storage.googleapis.com/odu-game-code/main.${this.currentHash}.js`;
    },
    cssSrc() {
      return `https://storage.googleapis.com/odu-game-code/main.${this.currentHash}.css`;
    },
    headLink() {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = this.cssSrc;
      link.type = 'text/css';
      return link;
    },
    pizzaGuyCallback() {
      let callback = this.noop;
      if (this.stepValid) callback = this.handleShowNext;
      if (this.gameRestart) callback = this.clearRestart;
      return callback;
    },
  },
  watch: {
    steamComplete(val) {
      if (val === 6) {
        this.handleFinalStep();
      }
    },
    canAnimate(val) {
      if (val && this.showWelcome && !this.hasTrackedWelcome) {
        this.tracking.trackEvent('e-game-screen-view', { gameScreen: 'intro' });
        this.hasTrackedWelcome = true;
      }
    },
  },
  mounted() {
    document.addEventListener('checkStepValid', this.checkStepIsValid);
    document.addEventListener('pizzaAnimInComplete', this.goToNextStep);
    document.addEventListener('toppingAdded', this.incrementToppings);
    document.addEventListener('setUserFunctionOrder', this.setUserFunctionOrder);
    if (this.currentStep > 0 || this.debug) {
      this.handleUpdateCode();
      this.$emit('welcome-loaded');
    }
  },
  beforeUnmount() {
    document.removeEventListener('checkStepValid', this.checkStepIsValid);
    document.removeEventListener('pizzaAnimInComplete', this.goToNextStep);
    document.removeEventListener('toppingAdded', this.incrementToppings);
    document.removeEventListener('setUserFunctionOrder', this.setUserFunctionOrder);
  },
  methods: {
    noop() {},
    handleBackToFinal() {
      this.showContactForm = false;
      setTimeout(() => {
        this.showFinalScreen = true;
      }, 300);
    },
    handleGoToContact(selectedSchool = null) {
      this.selectedSchool = selectedSchool;
      this.showFinalScreen = false;
      setTimeout(() => {
        this.showContactForm = true;
      }, 300);
    },
    handleStartGame() {
      this.tracking.trackEvent('e-game-lets-go');
      this.showWelcome = false;
      this.currentStep = 0;
      this.$emit('game-start');
      this.$emit('welcome-play', false);
      this.tracking.trackEvent('e-game-screen-view', { gameScreen: this.screenByIndex[this.currentStep] });
      this.startTimeForStep(this.currentStep);
    },
    startTimeForStep(step) {
      this.timeByStep[step] = new Date();
    },
    handleShowNext() {
      this.showNextButton = true;
    },
    restartGame() {
      this.currentStep = 0;
      this.gameRestart = true;
      this.handleUpdateCode();
      this.pizzaGuyCopy = 'something about restarting';
      this.$refs.pizzaGuy.animPizzaGuyIn();
    },
    clearRestart() {
      this.gameRestart = false;
    },
    handleUpdateCode(runCodeClick = false) {
      this.runCodeClick = runCodeClick;
      this.stepValid = false;
      this.userFunctionOrder = [];
      this.toppingsCount = 0;
      if (this.runCodeClick) {
        this.attemptsByStep[this.currentStep] += 1;
        this.tracking.trackEvent('e-game-run-code', {
          step: this.screenByIndex[this.currentStep],
          attempt: this.attemptsByStep[this.currentStep],
        });
        this.runCodeDisabled = true;
      }
      this.$refs.iframe.srcdoc = `
        <html>
          <head>
            <link rel="stylesheet" href='${this.cssSrc}'></link>
          </head>
          <body>
            ${this.codeValues.html}
            <style>${this.codeValues.css}</style>
            ${buildScriptSrcTag(this.javscriptSrc)}
            ${buildScriptTag(this.codeValues.javascript)}
          </body>
        </html>
      `;
    },
    checkStepIsValid(e) {
      console.log('check is valid called');
      if (!this.$refs.iframe || this.gameRestart) return;
      const { document } = this.$refs.iframe.contentWindow;
      if (this.currentStep > 0) {
        const stopToppingCallback = e.detail && e.detail.addTopping && this.currentStep !== 3;
        document.dispatchEvent(new CustomEvent('animatePizzaIn', { detail: { step: this.currentStep - 1, currentStepValid: false } }));
        if (!this.runCodeClick || stopToppingCallback) return;
      }
      const crust = document.getElementById('crust');
      let cheese;
      let classes;
      let sauce;
      let valid = false;
      switch (this.currentStep) {
        case 0:
          if (!crust) break;
          classes = Array.from(crust.classList);
          valid = classes.some((r) => this.validCrustClasses.includes(r));
          break;
        case 1:
          sauce = document.getElementById('sauce');
          // console.log(this.getFillAsHex(getComputedStyle(sauce).fill, 'in validate'));
          valid = getComputedStyle(sauce).fill !== 'none' && this.getFillAsHex(getComputedStyle(sauce).fill);
          break;
        case 2:
          cheese = document.getElementById('cheese');
          valid = getComputedStyle(cheese).display === 'block';
          break;
        case 3:
          valid = this.toppingsCount >= 2 && crust.querySelectorAll('.topping').length >= 1;
          break;
        case 4:
          // console.log(this.correctFunctionOrder, this.userFunctionOrder, 'baking validation');
          valid = this.arraysEqual(this.correctFunctionOrder, this.userFunctionOrder);
          break;
        default:
          break;
      }
      if (valid) {
        this.stepValid = true;
        document.dispatchEvent(new CustomEvent('animatePizzaIn', { detail: { step: this.currentStep, currentStepValid: true } }));
      } else if (this.currentStep !== 3 || (this.currentStep === 3 && e.detail)) {
        this.handleStepError();
      } else if (this.currentStep === 3 && !e.detail) {
        setTimeout(() => {
          if (this.toppingsCount === 0) {
            this.handleStepError();
          }
        }, 500);
      }
    },

    handleGameWin() {
      const svgEl = document.querySelector('.pizza-steam');
      const steamEls = Array.from(svgEl.querySelectorAll('.animate-steam'));
      gsap.fromTo(this.$refs.iframe, 3.5, { filter: 'brightness(1) contrast(1) saturate(1) sepia(0)' }, { filter: 'brightness(.8) contrast(1.07) saturate(1.5) sepia(0.5)', delay: 1 });
      steamEls.forEach((el) => {
        const tl = gsap.timeline({
          repeat: 1,
          onComplete: () => {
            this.steamComplete += 1;
          },
        });
        tl.set(el, { y: this.getRandom(0, 500) });
        tl.to(el, this.getRandom(1.3, 2.3),
          {
            y: -this.getRandom(50, 100),
            opacity: 1,
            ease: Power2.easeOut,
            delay: this.getRandom(1.3, 1.61),
          });
        tl.to(el, 0.5, { opacity: 0, ease: Power2.easeOut }, '-=0.5');
      });
    },

    handleFinalStep() {
      const tl = gsap.timeline();
      tl.to(this.$refs.steps.$el, 0.5, { opacity: 0, pointerEvents: 'none', ease: Power2.easeOut });
      tl.to(this.$refs.iframe, 0.5, { opacity: 0, pointerEvents: 'none', ease: Power2.easeOut }, '-=0.5');
      tl.call(() => {
        this.currentStep = null;
        this.showGameWin = true;
      });
    },

    handleFinalAnimComplete() {
      this.showGameWin = false;
      setTimeout(() => {
        this.showFinalScreen = true;
        this.tracking.trackEvent('e-game-screen-view', { gameScreen: 'complete' });
      }, 400);
    },

    handleGameError() {
      const tl = gsap.timeline({
        onComplete: () => {
          setTimeout(() => {
            this.showGameError = false;
            this.runCodeDisabled = false;
            gsap.to(this.$refs.steps.$el, 0.5, {
              opacity: 1,
              pointerEvents: 'all',
              ease: Power2.easeOut,
              delay: 0.5,
            });
            gsap.to(this.$refs.iframe, 0.5, {
              opacity: 1,
              pointerEvents: 'all',
              ease: Power2.easeOut,
              delay: 0.5,
            });
          }, 2000);
        },
      });
      tl.to(this.$refs.steps.$el, 0.5, {
        opacity: 0,
        pointerEvents: 'none',
        ease: Power2.easeOut,
        delay: 1.5,
      });
      tl.to(this.$refs.iframe, 0.5, { opacity: 0, pointerEvents: 'none', ease: Power2.easeOut }, '-=0.5');
      tl.call(() => {
        this.showGameError = true;
      });
    },

    incrementToppings() {
      this.toppingsCount += 1;
    },
    getAdditionalStepStyle() {
      let stepStyle = '';
      if (this.currentStep > 0) stepStyle += '#crust .crust {transform: scale(1);}';
      if (this.currentStep > 1) stepStyle += ' #sauce {opacity: 1;}';
      if (this.currentStep > 2) stepStyle += ' #cheese {transform: scale(1); opacity: 1;}';
      if (this.currentStep > 3) stepStyle += ' .topping {transform: scale(1); opacity: 1;}';
      return `<style>${stepStyle}</style>`;
    },
    setUserFunctionOrder(e) {
      this.userFunctionOrder.push(e.detail.call);
    },
    getPizzaGuyCopy() {
      switch (this.currentStep) {
        case 0:
          this.pizzaGuyCopy = this.getCrustCopy();
          break;
        case 1:
          this.pizzaGuyCopy = this.getSauceCopy();
          break;
        case 2:
          this.pizzaGuyCopy = this.successCopy.three;
          break;
        case 3:
          this.pizzaGuyCopy = this.successCopy.four[
            Math.floor(Math.random() * this.successCopy.four.length)
          ];
          break;
        default:
          this.pizzaGuyCopy = 'Generic Success';
          break;
      }
    },
    getSauceCopy() {
      const { document } = this.$refs.iframe.contentWindow;
      const sauce = document.getElementById('sauce');
      const fill = this.getFillAsHex(getComputedStyle(sauce).fill);
      let copyKey = 'other';
      Object.keys(this.fills).forEach((key) => {
        if (key.toLowerCase() === fill) copyKey = this.fills[key];
      });
      return this.successCopy.two[copyKey];
    },
    getCrustCopy() {
      const { document } = this.$refs.iframe.contentWindow;
      const crust = document.getElementById('crust');
      const crustClass = Array.from(crust.classList)[0];
      return this.successCopy.one[crustClass];
    },
    goToNextStep() {
      if (this.currentStep === 4) {
        const { document } = this.$refs.iframe.contentWindow;
        const crust = document.getElementById('crust');
        this.$emit('game-done');
        this.handleGameWin();
        let totalTimeSpent = 0;
        Object.keys(this.timeByStep).forEach((key) => { totalTimeSpent += this.timeByStep[key]; });
        const crustClass = Array.from(crust.classList)[0];
        const sauce = document.getElementById('sauce');
        const fill = this.getFillAsHex(getComputedStyle(sauce).fill);
        const toppings = crust.querySelectorAll('.topping');
        const toppingClasses = [];
        toppings.forEach((topping) => {
          const toppingName = topping.classList.value.split(' ')[1];
          if (toppingClasses.indexOf(toppingName) < 0) toppingClasses.push(toppingName);
        });
        this.tracking.trackEvent('e-game-complete', {
          totalTimeSpent: `${totalTimeSpent}`,
          crust: crustClass,
          hexCode: fill,
          toppings: toppingClasses,
        });
      } else {
        this.getPizzaGuyCopy();
        if (this.$refs) {
          this.tracking.trackEvent('e-game-success-anim-view', { step: this.screenByIndex[this.currentStep] });
          this.$refs.pizzaGuy.animPizzaGuyIn();
        }
      }
    },
    handleStepError() {
      this.pizzaGuyCopy = ERROR_OPTIONS[Math.floor(Math.random() * ERROR_OPTIONS.length)];
      this.tracking.trackEvent('e-game-error-anim-view', { step: this.screenByIndex[this.currentStep] });
      this.$refs.pizzaGuy.animPizzaGuyIn();
    },
    handlePizzaGuyComplete() {
      this.pizzaGuyCallback();
      this.runCodeDisabled = false;
    },
    handleIncremementStep() {
      const endTime = new Date();
      const elapsedMs = endTime - this.timeByStep[this.currentStep];
      const seconds = Math.round(elapsedMs / 1000);
      this.tracking.trackEvent(`e-game-next-${this.currentStep + 1}`, {
        totalAttempts: this.attemptsByStep[this.currentStep],
        timeSpent: seconds,
      });
      this.timeByStep[this.currentStep] = seconds;
      this.showNextButton = false;
      this.currentStep += 1;
      this.$emit('step-change', this.currentStep);
      this.startTimeForStep(this.currentStep);
      this.tracking.trackEvent('e-game-screen-view', { gameScreen: this.screenByIndex[this.currentStep] });
    },
    handleWelcomePlay(val) {
      this.$emit('welcome-play', val);
    },
    arraysEqual(a, b) {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;

      for (let i = 0; i < a.length; i += 1) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    },
    getRandom(min, max) {
      return Math.random() * (max - min) + min;
    },
    getFillAsHex(rgb) {
      const innerRGB = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
      function hex(x) {
        // eslint-disable-next-line
        return (`0${parseInt(x).toString(16)}`).slice(-2);
      }
      try {
        return `${hex(innerRGB[1])}${hex(innerRGB[2])}${hex(innerRGB[3])}`;
      } catch (error) {
        return null;
      }
    },
  },
};
</script>

<style lang="scss">
.pizza-app {
  position: relative;
  width: 70%;
  background: linear-gradient(0deg, #52ABC2 -16.12%, #002F62 97.23%);
  height: 100%;
  display: flex;
  overflow: hidden;
  .play-button {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  &__border {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    svg {
      display: block;
      pointer-events: none;
    }
  }
  &__stars {
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translate(-50%, 0);
    top: 0;
    z-index: 1;
  }
  .pizza-container {
    position: relative;
    z-index: 4;
    width: 50%;
    .pizza-steam {
      position: absolute;
      width: 60%;
      top: 25%;
      z-index: 2;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: white;
      pointer-events: none;
      overflow: visible;
      .animate-steam {
        opacity: 0;
      }
    }
  }
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>
