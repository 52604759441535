export const EVENTS = {
  'e-footer-contact': {
    event: 'e-footer-contact',
    hitType: 'event',
    eventCategory: 'footer',
    eventAction: 'click',
    eventLabel: 'Global - Footer - Contact - Click',
  },
  'e-footer-directions': {
    event: 'e-footer-directions',
    hitType: 'event',
    eventCategory: 'footer',
    eventAction: 'click',
    eventLabel: 'Global - Footer - Directions - Click',
  },
  'e-footer-learn-more': {
    event: 'e-footer-learn-more',
    hitType: 'event',
    eventCategory: 'footer',
    eventAction: 'click',
    eventLabel: 'Global - Footer - Learn More - Click',
  },
  'e-footer-social': {
    event: 'e-footer-social',
    hitType: 'event',
    eventCategory: 'footer',
    eventAction: 'click',
    eventLabel: 'Global - Footer - [SOCIAL_PLATFORM] - Click',
  },
  'e-footer-privacy': {
    event: 'e-footer-privacy',
    hitType: 'event',
    eventCategory: 'footer',
    eventAction: 'click',
    eventLabel: 'Global - Footer - Privacy - Click',
  },
  'e-footer-accessibility': {
    event: 'e-footer-accessibility',
    hitType: 'event',
    eventCategory: 'footer',
    eventAction: 'click',
    eventLabel: 'Global - Footer - Accessibility - Click',
  },
  'e-footer-info': {
    event: 'e-footer-info',
    hitType: 'event',
    eventCategory: 'footer',
    eventAction: 'click',
    eventLabel: 'Global - Footer - Consumer Info - Click',
  },
  'e-section-view': {
    event: 'e-section-view',
    hitType: 'event',
    eventCategory: 'section',
    eventAction: 'view',
    eventLabel: 'Home - Section - View - [SECTION]',
  },
  'e-hero-scroll-click': {
    hitType: 'event',
    eventCategory: 'section',
    eventAction: 'click',
    eventLabel: 'Home - Hero - Click',
  },
  'e-game-odu-cs-click': {
    event: 'e-game-odu-cs-click',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'click',
    eventLabel: 'Home - Game - ODU CS - Click',
  },
  'e-game-tab-click': {
    event: 'e-game-tab-click',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'click',
    eventLabel: 'Home - Game - Code Tab - Click - [TAB]',
  },
  'e-game-screen-view': {
    event: 'e-game-screen-view',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'view',
    eventLabel: 'Home - Game - Screen - View - [GAME_SCREEN]',
  },
  'e-game-lets-go': {
    event: 'e-game-lets-go',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'click',
    eventLabel: 'Home - Game - Intro - Let\'s Go - Click',
  },
  'e-game-run-code': {
    event: 'e-game-run-code',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'click',
    eventLabel: 'Home - Game - Run Code - Click - [STEP] - [ATTEMPT]',
  },
  'e-game-next-1': {
    event: 'e-game-next-1',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'click',
    eventLabel: 'Home - Game - Next - Cick - Crust - [TOTAL_ATTEMPTS] - [TIME_SPENT]',
  },
  'e-game-next-2': {
    event: 'e-game-next-2',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'click',
    eventLabel: 'Home - Game - Next - Cick - Sauce - [TOTAL_ATTEMPTS] - [TIME_SPENT]',
  },
  'e-game-next-3': {
    event: 'e-game-next-3',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'click',
    eventLabel: 'Home - Game - Next - Cick - Cheese - [TOTAL_ATTEMPTS] - [TIME_SPENT]',
  },
  'e-game-next-4': {
    event: 'e-game-next-4',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'click',
    eventLabel: 'Home - Game - Next - Cick - Toppings - [TOTAL_ATTEMPTS] - [TIME_SPENT]',
  },
  'e-game-success-anim-view': {
    event: 'e-game-success-anim-view',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'view',
    eventLabel: 'Home - Game - Success - View - [STEP]',
  },
  'e-game-error-anim-view': {
    event: 'e-game-error-anim-view',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'view',
    eventLabel: 'Home - Game - Error - View - [STEP]',
  },
  'e-game-complete': {
    event: 'e-game-complete',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'view',
    eventLabel: 'Home - Game - Complete - [TOTAL_TIME_SPENT] - [CRUST] - [HEX_CODE] - [TOPPINGS]',
  },
  'e-game-restart': {
    event: 'e-game-restart',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'click',
    eventLabel: 'Home - Game - Restart - Click - [STEP]',
  },
  'e-game-complete-claim': {
    event: 'e-game-complete-claim',
    hitType: 'event',
    eventCategory: 'game',
    eventAction: 'click',
    eventLabel: 'Home - Game - Complete - Claim - Click',
  },
  'e-contact-form-share': {
    event: 'e-contact-form-share',
    hitType: 'event',
    eventCategory: 'share',
    eventAction: 'click',
    eventLabel: 'Endscreen - [STUDENT_TYPE] - Share - [SOCIAL_PLATFORM]',
  },
  'e-winner-play-again': {
    event: 'e-winner-play-again',
    hitType: 'event',
    eventCategory: 'share',
    eventAction: 'clilck',
    eventLabel: 'Winner Page - Play Again - Click',
  },
  'e-winner-odu-cs': {
    event: 'e-winner-odu-cs',
    hitType: 'event',
    eventCategory: 'share',
    eventAction: 'click',
    eventLabel: 'Winner Page - ODU CS - Click',
  },
  'e-winner-share': {
    event: 'e-winner-share',
    hitType: 'event',
    eventCategory: 'share',
    eventAction: 'click',
    eventLabel: 'Winner Page - Share - [SOCIAL_PLATFORM]',
  },
  'e-high-school-submit': {
    event: 'e-high-school-submit',
    hitType: 'event',
    eventCategory: 'endscreen',
    eventAction: 'click',
    eventLabel: 'Endscreen - High School Submit - [SCHOOL_NAME] - Submit - click',
  },
  'e-high-school-error-click': {
    event: 'e-high-school-error-click',
    hitType: 'event',
    eventCategory: 'endscreen',
    eventAction: 'click',
    eventLabel: 'Endscreen - High School Submit - High School Not Found - CTA - click',
  },
  'e-high-school-skip-click': {
    event: 'e-high-school-skip-click',
    hitType: 'event',
    eventCategory: 'endscreen',
    eventAction: 'click',
    eventLabel: 'Endscreen - High School Submit - Non-VA Student - CTA - click',
  },
  'e-contact-form-submit': {
    event: 'e-contact-form-submit',
    hitType: 'event',
    eventCategory: 'endscreen',
    eventAction: 'click',
    eventLabel: 'Endscreen - Contact Form - [STUDENT_TYPE]- Submit - click',
  },
  'e-contact-form-back': {
    event: 'e-contact-form-back',
    hitType: 'event',
    eventCategory: 'endscreen',
    eventAction: 'click',
    eventLabel: 'Endscreen - Contact Form - Non-VA Student - back - click',
  },
};

export const TRACKING_TEMPLATE_CODES = {
  '[SOCIAL_PLATFORM]': 'socialPlatform',
  '[TEST]': 'test',
  '[TAB]': 'tab',
  '[GAME_SCREEN]': 'gameScreen',
  '[STEP]': 'step',
  '[ATTEMPT]': 'attempt',
  '[TOTAL_ATTEMPTS]': 'totalAttempts',
  '[TIME_SPENT]': 'timeSpent',
  '[TOTAL_TIME_SPENT]': 'totalTimeSpent',
  '[CRUST]': 'crust',
  '[HEX_CODE]': 'hexCode',
  '[TOPPINGS]': 'toppings',
  '[SHARE_TYPE]': 'shareType',
  '[SECTION]': 'section',
  '[SCHOOL_NAME]': 'schoolName',
  '[STUDENT_TYPE]': 'studentType',
};
