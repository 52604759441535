export const buildScriptTag = (content) => `
  <script>
    try {
      ${content}
    } catch(err) {
      somethingWentWrong();
    }
  </script>`;

export const buildScriptSrcTag = (src) => `<script src="${src}"></script>`;

export const preventFocus = (event) => {
  event.preventDefault();
};

export const isValidEmail = (addr) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(addr).toLowerCase());
};
