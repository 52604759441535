export default {
  data: () => ({
    isInView: false,
    observer: null,
    trackingBySectionId: {
      landing: 'hero',
      editor: 'game',
      callout: 'info',
    },
  }),
  beforeUnmount() {
    this.observer = null;
  },
  methods: {
    initObserver() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: [0.25, 0.5],
      };
      this.observer = new IntersectionObserver(this.elInView, options);
      this.observer.observe(this.$refs.landing.$el);
      this.observer.observe(this.$refs.editor.$el);
      this.observer.observe(document.getElementById('callout'));
    },
    elInView(entries) {
      entries.forEach((entry) => {
        if (entry.intersectionRatio <= 0.25) this.stopAnimations[entry.target.id] = true;
        if (entry.intersectionRatio >= 0.5) {
          this.tracking.trackEvent('e-section-view', { section: this.trackingBySectionId[entry.target.id] });
          if (this.stopAnimations[entry.target.id]) {
            this.stopAnimations[entry.target.id] = false;
          }
        }
      });
    },
  },
};
