<template>
  <div
    class="contact-form wForm"
    :class="{ visible: visible, noSub: !this.selectedSchool }"
    id="217866-WRPR"
  >
    <button class="contact-form__back" @click="handleBackClick" v-if="!selectedSchool">
      <Arrow />
      Back
    </button>
    <finalBg class="contact-form__bg" />
    <p class="contact-form__copy" v-html="copy" />
    <div class="codesection" id="code-217866"></div>
    <form
      class="contact-form__form hintsBelow labelsAbove"
      @submit="handleSubmit"
      method="post"
      action="https://olddominion.tfaforms.net/responses/processor"
      role="form"
      id="217866"
    >
      <div
        class="contact-form__form__input"
        v-for="item in formInputs"
        :key="item.label"
      >
        <FormInput
          v-if="item.type === 'input' || item.type === 'tel'"
          :required="item.required"
          :label="item.label"
          :id="item.id"
          :error="formErrors[item.id]"
          :placeholder="item.placeholder"
          :type="item.type"
          @inputChange="(val) => handleInputChange(item, val)"
        />
        <div class="form-select" v-else>
          <div class="form-select__labels">
            <label :for="item.label">{{item.label}}</label>
            <p class="required">*optional</p>
          </div>
          <div
            class="form-select__select"
            :class="{ hover }"
            @click.self="handleSelectClick"
            tabindex="0"
            @keydown="handleKeyDown"
          >
            <p class="form-select__select__label">{{selectLabel}}</p>
            <CaretDown class="form-select__select__icon" />
            <div
              class="form-select__select__options"
              :class="{ expanded: selectExpanded }"
            >
             <div
              class="form-select__select__options__wrapper"
              ref="selectOpts"
             >
              <span
                :class="{ active: activeSelectIndex === 0 }"
                id="tfa_8"
                class="option"
                @click="handleOptClick(0)"
              >
                Fall 2022
              </span>
              <span
                :class="{ active: activeSelectIndex === 1 }"
                id="tfa_9"
                class="option"
                @click="handleOptClick(1)"
              >
                Fall 2023
              </span>
              <span
                :class="{ active: activeSelectIndex === 2 }"
                id="tfa_10"
                class="option"
                @click="handleOptClick(2)"
              >
                Fall 2024
              </span>
              <span
                :class="{ active: activeSelectIndex === 3 }"
                id="tfa_11"
                class="option"
                @click="handleOptClick(3)"
              >
                Fall 2025
              </span>
              <span
                :class="{ active: activeSelectIndex === 4 }"
                id="tfa_12"
                class="option"
                @click="handleOptClick(4)"
              >
                Fall 2026
              </span>
              <span
                :class="{ active: activeSelectIndex === 5 }"
                id="tfa_13"
                class="option"
                @click="handleOptClick(5)"
              >
                Fall 2027
              </span>
              <span
                :class="{ active: activeSelectIndex === 6 }"
                id="tfa_14"
                class="option"
                @click="handleOptClick(6)"
              >
                Fall 2028
              </span>
             </div>
            </div>
          </div>
        </div>
      </div>
      <button class="contact-form__form__submit" id="217866-A">
        <span>Submit</span>
      </button>
       <input type="hidden" value="217866" name="tfa_dbFormId" id="tfa_dbFormId">
       <input
        type="hidden"
        value="8318ae6f866f6d4ca1ff1bc8f1a0d11b"
        name="tfa_dbControl"
        id="tfa_dbControl"
      >
       <input type="hidden" value="16" name="tfa_dbVersionId" id="tfa_dbVersionId">
    </form>
    <div class="contact-form__share">
      <h4 class="contact-form__share__copy">SHARE ON SOCIAL:</h4>
      <ul class="contact-form__share__list">
        <li class="contact-form__share__list__item download">
          <a
            :href="shareAsset"
            download="share.png"
            @click="handleShareClick('instagram')"
            aria-label="download"
          >
            <download />
          </a>
        </li>
        <li class="contact-form__share__list__item">
          <a
            :href="facebookShareLink"
            target="_blank"
            @click="handleShareClick('facebook')"
            aria-label="share on facebook"
          >
            <facebook />
          </a>
        </li>
        <li class="contact-form__share__list__item">
          <a
            :href="twitterShareLink"
            target="_blank"
            @click="handleShareClick('twitter')"
            aria-label="share on twitter"
          >
            <twitter />
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { isValidPhoneNumber } from 'libphonenumber-js';
import { isValidEmail } from '@/core/utils';
import finalBg from '@/assets/svg/final-background.svg';
import CaretDown from '@/assets/svg/caret-down.svg';
import facebook from '@/assets/svg/facebook.svg';
import twitter from '@/assets/svg/twitter.svg';
import download from '@/assets/svg/download.svg';
import winnerShare from '@/assets/images/winner-share.png';
import playerShare from '@/assets/images/player-share.png';
import Arrow from '@/assets/svg/arrow.svg';
import FormInput from './FormInput.vue';

export default {
  name: 'contact-form',
  inject: ['tracking'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selectedSchool: {
      required: true,
      default: {
        name: 'first colonial high school',
        city: 'virginia beach',
        state: 'va',
      },
    },
  },
  components: {
    finalBg,
    FormInput,
    CaretDown,
    facebook,
    twitter,
    download,
    Arrow,
  },
  computed: {
    scrollerStyle() {
      let top = (this.scrollBarHeight - this.scrollerHeight) * this.scrollPerc;
      const max = this.scrollBarHeight - this.scrollerHeight - 1;
      top = Math.min(Math.max(top, 1), max);
      return { top: `${top}px` };
    },
    shareAsset() {
      return this.playerShare;
    },
    facebookShareLink() {
      return `https://www.facebook.com/sharer.php?u=${this.url}&quote=${encodeURIComponent(this.prefillCopy)}`;
    },
    twitterShareLink() {
      return `https://twitter.com/intent/tweet?text=${this.prefillCopy}${encodeURIComponent(' @oducs')}&url=${this.url}`;
    },
    copy() {
      if (this.selectedSchool) {
        const { selectedSchool: { name } } = this;
        return `Your High School, <span class="yellow">${name}</span>,
                has been entered in the contest. Be sure to follow us on Facebook and Twitter for updates.
                For more information about Computer Science at ODU, please fill out the form below.`;
      }
      return `Not a VA high school student? Not a problem. If you’re still
              interested in more information about ODU, please
              fill out the form below (not required).`;
    },
    selectLabel() {
      return this.selectedYear !== null
        ? this.$refs.selectOpts.children[this.selectedYear].innerHTML
        : 'Please select...';
    },
  },
  data: () => ({
    scrollPerc: 0,
    scrollerHeight: 0,
    scrollBarHeight: 0,
    selectedYear: null,
    selectExpanded: false,
    activeSelectIndex: 0,
    hover: false,
    formData: {},
    formErrors: {},
    playerShare,
    winnerShare,
    prefillCopy: 'I just coded a virtual pizza. Check out Code4PizzaODU to code yours!',
    url: process.env.VUE_APP_SHARE_URL,
    formInputs: [
      {
        label: 'First Name',
        type: 'input',
        required: true,
        id: 'tfa_1',
      },
      {
        label: 'Last Name',
        type: 'input',
        required: true,
        id: 'tfa_2',
      },
      {
        label: 'E-mail',
        type: 'input',
        required: true,
        id: 'tfa_3',
      },
      {
        label: 'Start Semester & Year',
        type: 'dropdown',
        required: false,
        id: 'tfa_7',
      },
      {
        label: 'Mobile Number',
        type: 'tel',
        required: false,
        placeholder: '###-###-####',
        id: 'tfa_4',
      },
    ],
  }),
  methods: {
    bindScrollBar() {
      this.$nextTick(() => {
        this.$refs.resultsWrapper.removeEventListener('scroll', this.handleScroll);
        this.wrapperScrollHeight = this.$refs.resultsWrapper.scrollHeight;
        this.scrollBarHeight = this.$refs.scrollbar.clientHeight;
        this.scrollerHeight = this.$refs.scroller.clientHeight;
        this.$refs.resultsWrapper.scrollTop = 0;
        this.scrollPerc = 0;
        this.$refs.resultsWrapper.addEventListener('scroll', this.handleScroll);
      });
    },
    handleBackClick() {
      this.tracking.trackEvent('e-contact-form-back');
      this.$emit('back-to-final');
    },
    handleShareClick(share) {
      this.tracking.trackEvent('e-contact-form-share', {
        socialPlatform: share,
        studentType: this.selectedSchool ? 'VA Student' : 'Non-VA Student',
      });
    },
    handleScroll({ target }) {
      this.scrollPerc = target.scrollTop / (target.scrollHeight - target.clientHeight);
    },
    handleSelectClick(e) {
      e.preventDefault();
      if (this.selectExpanded) this.handleSelectClose();
      else this.handleSelectOpen();
    },
    handleSelectOpen() {
      this.selectExpanded = true;
    },
    handleSelectClose() {
      this.selectExpanded = false;
    },
    handleOptClick(opt = this.activeSelectIndex) {
      this.selectedYear = opt;
      this.activeSelectIndex = opt;
      this.handleSelectClose();
    },
    handleKeyDown(e) {
      if (e.code === 'Space' || e.code === 'Enter') {
        if (this.selectExpanded) this.handleOptClick(this.activeSelectIndex);
        else this.handleSelectOpen();
      }
      if (e.code === 'Escape' || e.code === 'Tab') {
        this.handleSelectClose();
        return;
      }
      e.preventDefault();
      if (e.code === 'ArrowDown') {
        this.activeSelectIndex = (this.activeSelectIndex + 1) % 7;
        this.scrollIfNeeded();
      }
      if (e.code === 'ArrowUp') {
        if (this.activeSelectIndex === 0) this.activeSelectIndex = 6;
        else this.activeSelectIndex = (this.activeSelectIndex - 1) % 7;
        this.scrollIfNeeded();
      }
    },
    scrollIfNeeded() {
      const { selectOpts } = this.$refs;
      const parentRect = selectOpts.getBoundingClientRect();
      const childRect = selectOpts.children[this.activeSelectIndex].getBoundingClientRect();
      const childOffset = childRect.top - parentRect.top;
      if (childOffset + childRect.height > parentRect.height) {
        selectOpts.scrollTop = childRect.top;
      } else if (childOffset < 0) {
        selectOpts.scrollTop += childOffset;
      }
    },
    handleInputChange(item, val) {
      this.formErrors[item.id] = null;
      this.formData[item.id] = val;
    },
    handleSubmit(e) {
      let valid = true;
      this.formInputs.forEach((input) => {
        if (input.required && !this.formData[input.id]) {
          valid = false;
          this.formErrors[input.id] = 'required';
        } else if (input.label === 'E-mail' && !isValidEmail(this.formData[input.id])) {
          this.formErrors[input.id] = 'invalid';
          valid = false;
        }
        if (input.type === 'tel' && this.formData[input.id] && !isValidPhoneNumber(this.formData[input.id], 'US')) {
          this.formErrors[input.id] = 'invalid';
          valid = false;
        }
      });
      if (!valid) e.preventDefault();
      this.tracking.trackEvent('e-contact-form-submit', { studentType: this.selectedSchool ? 'VA Student' : 'Non-VA Student' });
    },
  },
  mounted() { this.$emit('welcome-loaded'); },
};
</script>

<style lang="scss">
.contact-form {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 560px;
  background: #244370;
  box-shadow: 18px 13px 0px 0px #112A45;
  transform: translate(-50%, -50%);
  border: 7px solid $gameYellow;
  pointer-events: none;
  opacity: 0;
  z-index: 4;
  transition: opacity 400ms $easeOutQuad;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
  &.noSub {
    .contact-form__copy {
      margin: 50px auto 35px;
    }
  }
  &__back {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    color: white;
    font-family: $headlineFont;
    font-size: 10px;
    line-height: 1;
    cursor: pointer;
    text-transform: uppercase;
    top: 20px;
    left: 20px;
    &:hover {
      svg {
        transform: translate(-5px, -1px) rotate(180deg);
      }
    }
    svg {
      display: block;
      margin: 0 10px 0px 0;
      width: 10px;
      fill: white;
      transform: translateY(-1px) rotate(180deg);
      transition: transform 300ms $easeOutQuad;
    }
  }
  &__share {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: center;
    width: 100%;
    &__copy {
      font-family: $headlineFont;
      font-size: 10px;
      line-height: 16px;
      color: white;
    }
    &__list {
      display: flex;
      list-style: none;
      padding-left: 20px;
      &__item {
        width: 28px;
        height: 28px;
        background: white;
        border: 1px solid white;
        margin: 0 10px;
        a {
          cursor: pointer;
        }
        &.download {
          background: $gameYellow;
          display: flex;
          justify-content: center;
          align-items: center;
          a {
            width: 75%;
          }
        }
        svg {
          display: block;
        }
      }
    }
  }
  &::before, &::after {
      content: '';
      width: 5px;
      top: -7px;
      height: calc(100% + 14px);
      position: absolute;
      pointer-events: none;
    }
    &::before {
      background: $brightBlue;
      z-index: 1;
      left: -12px;
    }
    &::after {
      background: $gamePink;
      z-index: 1;
      right: -12px;
    }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: -1;
    fill: white;
  }
  &__copy {
    width: 90%;
    font-size: 18px;
    line-height: 26px;
    color: white;
    text-align: center;
    max-width: 746px;
    margin: 40px auto 25px;
    .yellow {
      color: #FBC504;
      text-transform: capitalize;
    }
  }
  &__form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    &__submit {
      background: #FBC504;
      position: relative;
      margin: 18px 17px 0px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 25px;
      padding: 10px 12px;
      cursor: pointer;
      transition: background 300ms $easeOutMaterial;
      z-index: 1;
      span {
        position: relative;
        z-index: 2;
        font-family: $headlineFont;
        text-transform: uppercase;
        font-size: 9px;
        line-height: 1;
        color: #17385C;
      }
      &:hover {
        background:#FFD646;
        &::before {
          background: #FBE38F;
        }
      }
      @include usingTabs {
        &:focus {
          background:#FFD646;
          &::before {
            background: #FBE38F;
          }
        }
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 50%;
        width: 100%;
        background:#FBD243;
        transition: background 300ms $easeOutMaterial;
      }
    }
    &__input {
      width: calc(50% - 34px);
      margin: 15px 17px;
      flex-shrink: 0;
      .form-select {
        position: relative;
        z-index: 2;
        &__labels {
          display: flex;
          align-items: center;
          justify-content: space-between;
          label, .required {
            font-family: $headlineFont;
            color: #FBC504;
            font-size: 8px;
            margin: 0 0 10px 0;
            text-transform: uppercase;
          }
          .required {
            font-size: 5px;
          }
        }
        &__select {
          position: relative;
          padding: 12px;
          background: #FFFFFF;
          cursor: pointer;
          transition: background 250ms $easeOutMaterial;
          &:hover {
            outline: none;
            background: #E5E5E5;
            &::after {
              background: rgba(138, 146, 163, 0.25);
            }
            .form-select__select__label {
              background: #fff;
            }
          }
          @include usingTabs {
            &:focus {
              outline: none;
              background: #E5E5E5;
              &::after {
                background: rgba(138, 146, 163, 0.25);
              }
              .form-select__select__label {
                background: #fff;
              }
            }
          }
          &::after {
            content: '';
            position: absolute;
            background: rgba(196, 196, 196, 0.25);
            width: 100%;
            height: 50%;
            bottom: 0;
            left: 0;
            z-index: 1;
            transition: background 250ms $easeOutMaterial;
          }
          &__icon {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translate(0, -50%);
            z-index: 3;
          }
          &__options {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            box-sizing: border-box;
            max-height: 0;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            background: #E2E2E2;
            font-family: $bodyFont;
            font-size: 12px;
            line-height: 1;
            transition: max-height 250ms $easeOutMaterial;
            will-change: max-height;
            &.expanded {
              max-height: 116px;
              overflow: hidden;
            }
            &__wrapper {
              overflow: scroll;
            }
            .option {
              display: flex;
              align-items: center;
              padding: 10px 19px 7px;
              transition: background 250ms $easeOutMaterial;
              &:hover {
                background: #D9DEE3;
              }
              @include usingTabs {
                &.active {
                  background: #D9DEE3;
                }
              }
            }
          }
          &__label {
            margin: 0;
            background: #E2E2E2;
            padding: 8px 10px;
            font-size: 10px;
            line-height: 1.15;
            text-transform: uppercase;
            position: relative;
            z-index: 2;
            transition: background 250ms $easeOutMaterial;
            pointer-events: none;
          }
        }
      }
    }
  }
}

</style>
