<template>
  <div class="welcome-container">
    <div class="welcome-container__body" :class="{ loaded }">
     <div class="content">
        <img
          :src="headlineImage"
          class="headline"
          :class="{ closed: contestEnded }"
          alt="Pizza here we come"
        />
        <p>
          {{introCopy}}
        </p>
        <ul>
          <li v-for="(rule, i) in content.rules" :key="rule">
            <span class="number" v-html="`#${i + 1}`" />
            <span v-html="rule" />
          </li>
        </ul>
        <button class="start-button" @click="$emit('start-game')">
          <span v-html="content.button" />
        </button>
     </div>
    </div>
    <div class="welcome-container__dorm" :class="{ loaded }">
      <img
        ref="dorm"
        class="welcome-container__dorm__lottie"
        :src="welcomeImage"
        @load="$emit('welcome-loaded')"
        alt="Dorm Pizza animation"
      />
      <img :src="oduPoster" class="welcome-container__dorm__poster" alt="odu dorm poster">
      <div class="welcome-container__dorm__bubble">
        <bubble />
        <p>NOT A CODER?<br>NOT A PROBLEM!</p>
      </div>
    </div>
  </div>
</template>

<script>
// import * as lottie from 'lottie-web';
// import introJSON from '@/assets/lottie/intro.json';
import welcomeHeadline from '@/assets/images/welcome-headline.png';
import welcomeHeadlineClosed from '@/assets/images/welcome-headline-closed.png';
import bubble from '@/assets/svg/bubble.svg';
import oduPoster from '@/assets/images/odu-poster.png';
import welcomeImage from '@/assets/images/welcome-intro.gif';

export default {
  components: {
    bubble,
  },
  data: () => ({
    content: {
      button: '&lt;LET’S_GO!&gt;',
      rules: [
        'All instructions are on the recipe card. Follow them carefully.',
        'All coding is done in the editor on the left. Make sure you check for typos.',
        'After you’ve finished writing your code, click <span class="code">&lt;RUN_MY_CODE&gt;</span> to move to the next step.',
        'Finish all 5 steps correctly, and claim your code for pizza!',
      ],
    },
    loaded: true,
    isScrolling: null,
    oduPoster,
    welcomeImage,
  }),
  props: {
    canAnimate: {
      type: Boolean,
      default: false,
    },
    contestEnded: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    introCopy() {
      if (this.contestEnded) {
        return 'That’s right, you can code your very own pizza. Not a coder? We’ve got you covered. Here are a few simple rules to get you started:';
      }
      return 'That’s right, your free pizza awaits. But before you get pizza, you’ll have to code a pizza. Not a coder? We’ve got you covered. Here are a few simple rules to get you started:';
    },
    headlineImage() {
      return this.contestEnded ? welcomeHeadlineClosed : welcomeHeadline;
    },
  },
  mounted() {
    // this.lottie = lottie.loadAnimation({
    //   container: this.$refs.dorm,
    //   animationData: introJSON,
    //   renderer: 'svg',
    //   autoplay: false,
    //   loop: true,
    //   name: 'welcome-lottie',
    // });
    // this.lottie.addEventListener('DOMLoaded', () => {
    //   this.loaded = true;
    //   this.$emit('welcome-loaded');
    // });
    // window.addEventListener('scroll', this.checkScrollEnd, false);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.checkScrollEnd);
  },
  methods: {
    checkScrollEnd() {
      this.lottie.pause();
      window.clearTimeout(this.isScrolling);
      this.$emit('welcome-play', false);
      // Set a timeout to run after scrolling ends
      this.isScrolling = setTimeout(() => {
        if (this.canAnimate) {
          this.$emit('welcome-play', true);
          this.lottie.play();
        }
      }, 200);
    },
  },
};
</script>

<style lang="scss">
.welcome-container {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 100%;
  top: 50%;
  height: 100%;
  left: 50%;
  z-index: 30;
  transform: translate(-50%, -50%);
  &__body, &__dorm {
    width: 50%;
    max-width: 380px;
    margin-left: 25px;
    opacity: 0;
    transition: opacity 500ms $authenticMotion;
    &.loaded {
      opacity: 1;
    }
  }
  &__body {
    background: $darkBlue;
    position: relative;
    height: 80%;
    margin-top: -2%;
    border: 7px solid $gameYellow;
    box-shadow: 18px 13px 0px 0px #112A45;
    &::before, &::after {
      content: '';
      width: 5px;
      top: -7px;
      height: calc(100% + 14px);
      position: absolute;
      pointer-events: none;
    }
    &::before {
      background: $brightBlue;
      z-index: 1;
      left: -12px;
    }
    &::after {
      background: $gamePink;
      z-index: 1;
      right: -12px;
    }
    .content {
      height: calc(100% - 105px);
      overflow-y: auto;
      padding: 20px 40px 10px;
    }
    .headline {
      width: 70%;
      max-width: 270px;
      margin: 15px 0 5px;
      &.closed {
        width: 100%;
      }
    }
    p {
      font-size: 14px;
      line-height: 21px;
      color: white;
    }
    ul {
      list-style: none;
      padding-left: 60px;
      margin: 0;
      margin-top: 20px;
      li {
        font-family: $bodyFont;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 16px;
        color: #F8D2A7;
        align-items: center;
        position: relative;
        &:last-child {
          margin-bottom: 0;
        }
        .code {
          font-family: $headlineFont;
          color: white;
          font-size: 8px;
          line-height: 16px;
        }
        .number {
          position: absolute;
          left: -60px;
          top: 3px;
          font-size: 16px;
          font-family: $headlineFont;
          color: $gameYellow;
          text-shadow: -2px 0px 0px $brightBlue, 2px 0px 0px $gamePink, 5px 3px 0px #112A45;
        }
      }
    }
    .start-button {
      position: absolute;
      bottom: 35px;
      left: 40px;
      background: none;
      border: none;
      padding: 0;
      cursor: pointer;
      span {
        cursor: pointer;
        display: block;
        font-family: $headlineFont;
        font-size: 10px;
        color: $darkBlue;
        text-align: center;
        padding: 15px;
        background: $gameYellow;
        text-transform: uppercase;
        border: 2px solid white;
        transition: border-color 300ms $easeOutQuad, background 300ms $easeOutQuad;
      }
      &:hover {
        span {
          background: white;
          border-color: $gameYellow;
        }
      }
    }
  }
  &__dorm {
    max-width: 550px;
    margin-top: 100px;
    position: relative;
    &__bubble {
      position: absolute;
      width: 175px;
      top: 29%;
      left: 43%;
      transform: translate(-60%, -105%);
      z-index: 10;
      p {
        width: 80%;
        text-align: center;
        position: absolute;
        top: 30%;
        left: 15px;
        transform: translateY(-50%);
        font-family: $headlineFont;
        font-size: 10px;
        line-height: 16px;
        text-transform: uppercase;
      }
    }
    &__poster {
      position: absolute;
      right: 5%;
      top: 25%;
      transform: translate(-50%, -50%);
      z-index: -1;
      width: 20%;
    }
    &__lottie {
      width: 100%;
    }
  }
}
</style>
