<template>
  <div ref="pizzaGuy" class="pizza-guy">
    <div class="pizza-guy__guy" ref="pizzaGuyLottie" :class="{ safari: isSafari }" />
    <div class="pizza-guy__bubble" ref="bubble" :class="{ windows: isWindows }">
      <bubble />
      <p>{{copy}}</p>
    </div>
  </div>
</template>

<script>
import * as lottie from 'lottie-web';
import uaParser from 'ua-parser-js';
import gsap, { Power2 } from 'gsap';
import pizzaGuyJson from '@/assets/lottie/pizza-guy.json';
import bubble from '@/assets/svg/bubble.svg';

export default {
  name: 'pizza-guy',
  components: {
    bubble,
  },
  props: {
    copy: {
      type: String,
      default: 'Pizza Guy Copy',
    },
    isSafari: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    pizzaGuy: null,
  }),
  computed: {
    parsedAgent() {
      return uaParser(window.navigator.userAgent);
    },
    isWindows() {
      return this.parsedAgent.os.name === 'Windows';
    },
  },
  mounted() {
    this.pizzaGuy = lottie.loadAnimation({
      container: this.$refs.pizzaGuyLottie,
      animationData: pizzaGuyJson,
      renderer: 'svg',
      autoplay: false,
      loop: false,
    });
  },
  methods: {
    animPizzaGuyIn() {
      const tl = gsap.timeline();
      const pizzaAppRect = document.getElementById('pizza-app').getBoundingClientRect();
      this.pizzaGuy.playSegments([0, 29], true);
      tl.to(this.$refs.pizzaGuy, 1.05, { x: 0, ease: Power2.easeOut });
      tl.to(this.$refs.bubble, 0.5, { opacity: 1, ease: Power2.easeOut });
      setTimeout(() => {
        tl.to(this.$refs.bubble, 0.5, { opacity: 0, ease: Power2.easeOut });
        tl.call(() => {
          this.pizzaGuy.playSegments([29, 82], true);
        });
        tl.to(this.$refs.pizzaGuy, 2, { x: -pizzaAppRect.width + 100, ease: Power2.easeOut }, '+=0.6');
        tl.call(() => { this.$emit('pizza-guy-complete'); });
        tl.set(this.$refs.pizzaGuy, { x: '100%' });
      }, 4000);
    },
  },
};
</script>

<style lang="scss">
.pizza-guy {
  position: absolute;
  pointer-events: none;
  bottom: 0%;
  right: 0;
  transform: translateX(100%);
  z-index: 10000;
  svg {
    display: block;
    width: 100%;
  }
  &__guy {
    display: block;
    svg {
      filter: url(#pixelate);
    }
    &.safari {
      svg {
        filter: none;
      }
    }
  }
  &__bubble {
    opacity: 0;
    position: absolute;
    width: 225px;
    top: 30%;
    left: 0;
    transform: translate(-60%, -105%);
    p {
      width: 80%;
      position: absolute;
      top: 30%;
      left: 17px;
      transform: translateY(-50%);
      font-family: $headlineFont;
      font-size: 10px;
      line-height: 16px;
      text-transform: uppercase;
    }
    &.windows {
      p {
        font-size: 12px;
      }
    }
  }
}
</style>
