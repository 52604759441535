<template>
  <div class="final-screen" :class="{ visible: visible }">
    <finalBg class="final-screen__bg" />
    <div class="final-screen__header">
      <ul class="final-screen__header__list">
        <li class="final-screen__header__list__item">
          <img :src="pizzaRicky" alt="pizza">
        </li>
        <li class="final-screen__header__list__item">
          <img :src="dancer" alt="pizza dancer">
        </li>
        <li class="final-screen__header__list__item">
          <img :src="pizzaMiles" alt="pizza">
        </li>
        <li class="final-screen__header__list__item">
          <img :src="dancer" alt="pizza dancer">
        </li>
        <li class="final-screen__header__list__item">
          <img :src="pizzaSeana" alt="pizza">
        </li>
        <li class="final-screen__header__list__item">
          <img :src="dancer" alt="pizza dancer">
        </li>
        <li class="final-screen__header__list__item">
          <img :src="pizzaKali" alt="pizza">
        </li>
      </ul>
      <img :src="codedPizza" class="final-screen__header__headline" alt="You coded a pizza">
    </div>
    <div class="final-screen__body" v-if="!contestEnded">
      <p class="final-screen__body__copy">
        Congratulations, you just coded a perfect pizza!<br>
        Enter your school in our contest for a chance to win a pizza party.
      </p>
      <div class="final-screen__body__search">
        <input
          class="final-screen__body__search__input"
          v-model="schoolName"
          placeholder="Start typing your school’s name"
        />
        <transition name="fade" mode="out-in">
          <div class="final-screen__body__search__results" v-if="results && results.length">
            <div class="scroll-bar" v-if="results.length > 4" ref="scrollbar">
              <span class="scroll-bar__scroller" ref="scroller" :style="scrollerStyle" />
            </div>
            <div class="results-wrapper" ref="resultsWrapper">
              <div
                class="result"
                v-for="(result, index) in results"
                :key="index"
                @click="handleChooseSchool(result)"
                :class="`result--${index}`"
              >
                <p>{{result.item.name}}</p>
                <p>{{result.item.city}}</p>
              </div>
            </div>
          </div>
          <div
            class="final-screen__body__search__no-results"
            v-else-if="showNoResults && inputData"
          >
            <p class="headline">Can’t find your school?</p>
            <p>
              The contest is available
              for participating high schools in the state of Virginia only.
              Don’t worry! You can still learn more about
              <a @click="() => handleContactShow('no-res')">Computer Science at ODU</a>.
            </p>
          </div>
        </transition>
      </div>
      <button
        class="final-screen__body__submit"
        :disabled="!selectedSchool"
        @click="handleSubmitSchool"
      >
        <span>Submit</span>
      </button>
      <transition name="fade">
        <p class="final-screen__body__error" v-if="submitError">
          Something went wrong please wait a few seconds and try again.
        </p>
      </transition>
      <p class="final-screen__body__copy bottom">
        Not a Virginia High School Student?
        That’s OK, you’re still an awesome coder. <br>
        Learn more about <a @click="() => handleContactShow()">Computer Science at ODU</a>.
      </p>
    </div>
    <div class="final-screen__body" v-else>
      <p class="final-screen__body__copy">
        Congratulations, you just coded a perfect pizza!<br>
        We’re fresh out of pies but feel free to share your experience.
      </p>
      <div class="final-screen__body__share">
        <h4 class="final-screen__body__share__copy">SHARE ON SOCIAL:</h4>
        <ul class="final-screen__body__share__list">
          <li class="final-screen__body__share__list__item download">
            <a
              :href="shareAsset"
              download="share.png"
              aria-label="download"
            >
              <download />
            </a>
          </li>
          <li class="final-screen__body__share__list__item">
            <a
              :href="facebookShareLink"
              target="_blank"
              aria-label="share on facebook"
            >
              <facebook />
            </a>
          </li>
          <li class="final-screen__body__share__list__item">
            <a
              :href="twitterShareLink"
              target="_blank"
              aria-label="share on twitter"
            >
              <twitter />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import finalBg from '@/assets/svg/final-background.svg';
import dancer from '@/assets/images/pizza-dance.png';
import facebook from '@/assets/svg/facebook.svg';
import twitter from '@/assets/svg/twitter.svg';
import download from '@/assets/svg/download.svg';
import pizzaMiles from '@/assets/images/pizza-miles.png';
import pizzaKali from '@/assets/images/pizza-kali.png';
import pizzaRicky from '@/assets/images/pizza-ricky.png';
import pizzaSeana from '@/assets/images/pizza-seana.png';
import codedPizza from '@/assets/images/coded-pizza.png';
import winnerShare from '@/assets/images/winner-share.png';
import playerShare from '@/assets/images/player-share.png';

export default {
  name: 'final-screen',
  inject: ['tracking'],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    contestEnded: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    finalBg,
    facebook,
    twitter,
    download,
  },
  computed: {
    facebookShareLink() {
      return `https://www.facebook.com/sharer.php?u=${this.url}&quote=${encodeURIComponent(this.prefillCopy)}`;
    },
    twitterShareLink() {
      return `https://twitter.com/intent/tweet?text=${this.prefillCopy}${encodeURIComponent(' @oducs')}&url=${this.url}`;
    },
    shareAsset() {
      if (this.allPizzasGone) return this.playerShare;
      return this.winnerShare;
    },
    schoolName: {
      get() {
        return this.inputData;
      },
      set(val) {
        this.inputData = val;
      },
    },
    scrollerStyle() {
      let top = (this.scrollBarHeight - this.scrollerHeight) * this.scrollPerc;
      const max = this.scrollBarHeight - this.scrollerHeight - 1;
      top = Math.min(Math.max(top, 1), max);
      return { top: `${top}px` };
    },
  },
  data: () => ({
    dancer,
    pizzaMiles,
    pizzaKali,
    pizzaRicky,
    pizzaSeana,
    codedPizza,
    winnerShare,
    playerShare,
    prefillCopy: 'I just coded a virtual pizza. Check out Code4PizzaODU to code yours!',
    url: `${process.env.VUE_APP_SHARE_URL}share`,
    authLink: process.env.VUE_APP_CLAIM_URL,
    inputData: '',
    results: null,
    ignoreChange: false,
    scrollPerc: 0,
    scrollerHeight: 0,
    selectedSchool: null,
    scrollBarHeight: 0,
    submitError: false,
  }),
  watch: {
    inputData(val) {
      if (!val || this.ignoreChange) {
        this.results = null;
        return;
      }
      this.selectedSchool = null;
      this.results = this.fuse.search(val);
      if (this.results.length > 4) this.bindScrollBar();
      if (this.results.length === 0) this.showNoResults = true;
    },
  },
  methods: {
    bindScrollBar() {
      this.$nextTick(() => {
        this.$refs.resultsWrapper.removeEventListener('scroll', this.handleScroll);
        this.wrapperScrollHeight = this.$refs.resultsWrapper.scrollHeight;
        this.scrollBarHeight = this.$refs.scrollbar.clientHeight;
        this.scrollerHeight = this.$refs.scroller.clientHeight;
        this.$refs.resultsWrapper.scrollTop = 0;
        this.scrollPerc = 0;
        this.$refs.resultsWrapper.addEventListener('scroll', this.handleScroll);
      });
    },
    handleScroll({ target }) {
      this.scrollPerc = target.scrollTop / (target.scrollHeight - target.clientHeight);
    },
    handleContactShow(type = 'skip') {
      const id = type === 'skip' ? 'e-high-school-skip-click' : 'e-high-school-error-click';
      this.tracking.trackEvent(id);
      this.$emit('go-to-contact');
    },
    handleChooseSchool(result) {
      this.ignoreChange = true;
      this.inputData = result.item.name;
      this.results = null;
      this.showNoResults = false;
      this.selectedSchool = result.item;
      this.$nextTick(() => {
        this.ignoreChange = false;
      });
    },
    handleSubmitSchool() {
      const { selectedSchool: { ceeb_code: ceebCode, name, city } } = this;
      this.tracking.trackEvent('e-high-school-submit', { schoolName: `${name}, ${city}` });
      fetch('/api/submission', {
        method: 'POST',
        body: `ceeb_code=${ceebCode}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': window.csrfToken,
        },
      }).then((res) => res.json())
        .then((response) => {
          if (!response.success) {
            if (this.errorTimeout) clearTimeout(this.errorTimeout);
            this.submitError = true;
            this.errorTimeout = setTimeout(() => {
              this.submitError = false;
            }, 3000);
            return;
          }
          this.$emit('go-to-contact', this.selectedSchool);
        });
    },
  },
  mounted() {
    this.$emit('welcome-loaded');
    fetch('/api/schools').then((res) => res.json()).then(({ data }) => {
      this.fuse = new Fuse(data, {
        includeScore: true,
        keys: ['name'],
        threshold: 0.1,
      });
    });
  },
  beforeUnmount() {
    if (this.errorTimeout) clearTimeout(this.errorTimeout);
  },
};
</script>

<style lang="scss">
.final-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 560px;
  background: #244370;
  box-shadow: 18px 13px 0px 0px #112A45;
  transform: translate(-50%, -50%);
  border: 7px solid $gameYellow;
  pointer-events: none;
  opacity: 0;
  z-index: 4;
  transition: opacity 400ms $easeOutQuad;
  &.visible {
    opacity: 1;
    pointer-events: all;
  }
  &::before, &::after {
      content: '';
      width: 5px;
      top: -7px;
      height: calc(100% + 14px);
      position: absolute;
      pointer-events: none;
    }
    &::before {
      background: $brightBlue;
      z-index: 1;
      left: -12px;
    }
    &::after {
      background: $gamePink;
      z-index: 1;
      right: -12px;
    }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: -1;
    fill: white;
  }
  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__error {
      position: absolute;
      left: 50%;
      bottom: 130px;
      font-family: $headlineFont;
      font-size: 6px;
      text-align: center;
      text-transform: uppercase;
      transform: translateX(-50%);
      color: white;
    }
    &__submit {
      position: absolute;
      bottom: 100px;
      background: #FBC504;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 12px;
      cursor: pointer;
      transition: background 300ms $easeOutMaterial;
      z-index: 1;
      &:disabled {
        opacity: 0.5;
        pointer-events: none;
      }
      span {
        position: relative;
        z-index: 2;
        font-family: $headlineFont;
        text-transform: uppercase;
        font-size: 9px;
        line-height: 1;
        color: #17385C;
      }
      &:hover {
        background:#FFD646;
        &::before {
          background: #FBE38F;
        }
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 50%;
        width: 100%;
        background:#FBD243;
        transition: background 300ms $easeOutMaterial;
      }
    }
    &__search {
      position: relative;
      width: 90%;
      max-width: 440px;
      border: 3px solid white;
      padding: 12px 8px;
      box-sizing: border-box;
      background: #FBC504;
      margin: 25px 0;
      z-index: 2;
      transition: background 300ms $easeOutMaterial;
      &:hover {
        background:#FFD646;
        &::before  { opacity: 1; }
        .final-screen__body__search {
          &__input { background: #FFEEB3;}
        }
      }
      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 23px;
        left: 0;
        top: 0;
        z-index: 1;
        background:#FBE38F;
        opacity: 0.45;
        transition: opacity 300ms $easeOutMaterial;
      }
      &__input {
        position: relative;
        z-index: 2;
        height: 22px;
        width: 100%;
        background: #FBE38F;
        border: none;
        box-sizing: border-box;
        padding: 0 8.5px;
        font-family: $headlineFont;
        font-size: 10px;
        line-height: 16px;
        text-transform: uppercase;
        color: #17385C;
        transition: background 300ms $easeOutMaterial;
        &:focus {
          outline: none;
          &::placeholder {
            opacity: 0;
          }
        }
        &::placeholder {
          opacity: 1;
          color: #17385C;
          transition: opacity 300ms $easeOutMaterial;
        }
      }
      &__no-results {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p {
          text-align: center;
          margin-top: 0;
          font-size: 12px;
          max-width: 340px;
          &.headline {
            text-transform: uppercase;
            font-size: 12px;
            line-height: 1.8;
            margin: 20px;
          }
          a {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      &__results {
        position: relative;
        .results-wrapper {
          scrollbar-width: none;
          max-height: 134px;
          overflow-y: scroll;
          -ms-overflow-style: none;
          &::-webkit-scrollbar { display: none; }
        }
        .scroll-bar {
          position: absolute;
          width: 5px;
          height: 90%;
          background: #95A3B3;
          right: 0;
          bottom: 50%;
          transform: translateY(50%);
          &__scroller {
            position: absolute;
            width: 3px;
            height: 18%;
            left: 1px;
            top: 1px;
            background: #17385C;
          }
        }
        .result {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px;
          cursor: pointer;
          margin-right: 20px;
          background: rgba(#FBE38F, 0);
          transition: background 250ms $easeOutMaterial;
          &:hover {
            background: #FBE38F;
          }
          &--0 {
            margin-top: 10px;
          }
          &:last-of-type  {
            padding-bottom: 10px;
          }
          p {
            margin: 0;
            font-size: 14px;
            line-height: 16px;
            text-transform: capitalize;
          }
        }
      }
    }
    &__copy {
      margin: 0;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: white;
      &.bottom {
        font-size: 11px;
        line-height: 20px;
        position: absolute;
        bottom: 30px;
        z-index: 1;
        a {
          position: relative;
          cursor: pointer;
          &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: -2px;
            background: white;
            left: 0;
            transform-origin: center center;
            transition: transform 250ms $easeOutMaterial;
            transform: scaleX(1);
          }
        }
      }
    }
    &__share {
      display: flex;
      align-items: center;
      margin-top: 20px;
      &__copy {
        font-family: $headlineFont;
        font-size: 10px;
        line-height: 16px;
        color: white;
      }
      &__list {
        display: flex;
        list-style: none;
        padding-left: 20px;
        &__item {
          width: 28px;
          height: 28px;
          background: white;
          border: 1px solid white;
          margin: 0 10px;
          a {
            cursor: pointer;
          }
          &.download {
            background: $gameYellow;
            display: flex;
            justify-content: center;
            align-items: center;
            a {
              width: 75%;
            }
          }
          svg {
            display: block;
          }
        }
      }
    }
  }
  &__header {
    width: 100%;
    margin: 35px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__headline {
      width: 90%;
      max-width: 743px;
      margin-top: 20px;
    }
    &__list {
      max-width: 585px;
      display: flex;
      padding: 0;
      margin: 0;
      &__item {
        width: calc(100% / 7);
        height: auto;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        img {
          width: 100%;
        }
      }
    }
  }
}

</style>
