<template>
  <main ref="root">
    <Landing
      @loaded="handleSectionReady"
      :canAnimate="!stopAnimations.landing"
      :smallDevice="isSmallDevice"
      :contestStarted="contestStarted"
      :contestEnded="contestEnded"
      :daysRemaining="daysRemaining"
      :isSafari="isSafari"
      v-if="appMounted"
      ref="landing"
    />
    <Editor
      @loaded="handleSectionReady"
      :canAnimate="!stopAnimations.editor"
      :submissionsEnabled="submissionsEnabled"
      :contestEnded="contestEnded"
      :isSafari="isSafari"
      ref="editor"
      v-if="!isSmallDevice && appMounted"
    />
    <AppFooter v-if="appMounted && !isSmallDevice" :contestEnded="contestEnded" />
  </main>
</template>

<script>
import _ from 'lodash';
import uaParser from 'ua-parser-js';
import fastdom from 'fastdom';
import Observer from '@/mixins/observer';
import Editor from '@/components/Editor.vue';
import Landing from '@/components/Landing.vue';
import AppFooter from '@/components/AppFooter.vue';

export default {
  name: 'App',
  mixins: [Observer],
  inject: ['tracking'],
  emits: ['page-ready', 'show-intro'],
  components: {
    Editor,
    Landing,
    AppFooter,
  },
  data: () => ({
    sectionsReady: 0,
    introLoaded: false,
    lottie: null,
    appMounted: false,
    window: {
      width: 0,
      height: 0,
    },
    apiLoaded: false,
    submissionsEnabled: true,
    endDate: null,
    daysRemaining: null,
    contestEnded: false,
    contestStarted: false,
    stopAnimations: {
      landing: false,
      editor: true,
    },
  }),
  computed: {
    isSmallDevice() {
      return this.window.width < 1025;
    },
    parsedAgent() {
      return uaParser(window.navigator.userAgent);
    },
    isSafari() {
      return this.parsedAgent.browser.name === 'Safari'
        || this.parsedAgent.os.name === 'iOS'
        || this.parsedAgent.os.name === 'Windows';
    },
  },
  watch: {
    sectionsReady(val) {
      if (val >= 2 && !this.isSmallDevice && this.apiLoaded) {
        this.handlePageReady();
      } else if (val >= 1 && this.isSmallDevice && this.apiLoaded) {
        this.handlePageReady(false);
      }
    },
    apiLoaded() {
      if (
        (this.sectionsReady >= 1 && this.isSmallDevice)
        || (this.sectionsReady >= 2 && !this.isSmallDevice)
      ) {
        this.handlePageReady();
      }
    },
  },
  mounted() {
    this.tracking.trackPageView('Homepage');
    this.$emit('show-intro');
    this.updateWindowSizeDebounced = _.debounce(() => { this.updateWindowSize(); }).bind(this);
    window.addEventListener('resize', this.updateWindowSizeDebounced);
    this.updateWindowSizeDebounced();
    this.appMounted = true;
    fetch('/api/campaign').then((res) => res.json()).then((response) => {
      const {
        contest_started: contestStarted,
        submissions_enabled: submissionsEnabled,
        end_date: endDate,
      } = response.data;
      this.submissionsEnabled = submissionsEnabled;
      this.contestStarted = contestStarted;
      this.endDate = endDate;
      this.daysRemaining = this.getDaysRemaining(this.endDate);
      this.contestEnded = this.daysRemaining <= 0;
      this.apiLoaded = true;
    });
  },
  methods: {
    handleSectionReady() {
      this.sectionsReady += 1;
    },
    getDaysRemaining(endTime) {
      const oneDay = 24 * 60 * 60 * 1000;
      const today = new Date().setHours(0, 0, 0);
      const endDate = new Date(endTime);
      return Math.round((endDate - today) / oneDay);
    },
    handlePageReady(addObserver = true) {
      this.$emit('page-ready');
      if (addObserver) {
        this.$nextTick(() => {
          this.initObserver();
        });
      }
    },
    updateWindowSize() {
      let width;
      let height;
      fastdom.measure(() => {
        width = window.innerWidth;
        height = window.innerHeight;
      });
      fastdom.mutate(() => {
        this.window = { width, height };
      });
    },
    getDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    },
  },
};
</script>
