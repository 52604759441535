<template>
  <div class="footer">
    <border class="footer__border" />
    <div class="content">
      <img :src="oduLogo" class="footer__logo" alt="odu logo">
      <div class="footer__info">
        <div class="footer__info__left">
          <div>
            <a href="https://www.odu.edu/about/contact" target="_blank" @click="tracking.trackEvent('e-footer-contact')">
              <mail />
              CONTACT & MAILING INFO
            </a>
          </div>
          <div>
            <a href="https://www.odu.edu/about/visitors/directions " target="_blank" @click="tracking.trackEvent('e-footer-directions')">
              <maps class="maps" />
              DIRECTIONS TO CAMPUS
            </a>
          </div>
          <p>LEARN MORE AT
            <a href="https://www.odu.edu/compsci" target="_blank" @click="tracking.trackEvent('e-footer-learn-more')">
              ODU.EDU/COMPSCI
            </a>
          </p>
        </div>
        <div class="footer__info__right">
          <p>Connect with #ODU</p>
          <ul class="share">
            <li>
              <a href="https://www.facebook.com/oducompsci" target="_blank" @click="handleTrackSocial('facebook')" aria-label="facebook">
                <facebook />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/oducs" target="_blank" @click="handleTrackSocial('twitter')" aria-label="twitter">
                <twitter />
              </a>
            </li>
            <li>
              <a href="https://instagram.com/oducompsci?igshid=vs0341kkscrm" target="_blank" @click="handleTrackSocial('instagram')" aria-label="instgram">
                <img :src="instagram" alt="instagram" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer__bottom">
        <p class="copyright">
          <a v-if="!contestEnded" href="https://odu.edu/ttp/code4pizzaodu" target="_blank">
            Click here for Contest Details
          </a>
          <span>Copyright © Old Dominion University • Updated 11/27/2020</span>
        </p>
        <ul class="footer__links">
          <li>
            <a href="https://odu.edu/privacy" target="_blank" @click="tracking.trackEvent('e-footer-privacy')">
              Privacy
            </a>
          </li>
          <li>
            <a href="https://odu.edu/educationalaccessibility" target="_blank" @click="tracking.trackEvent('e-footer-accessibility')">
              Accessibility
            </a>
          </li>
          <li>
            <a href="https://odu.edu/about/compliance/transparency" target="_blank" @click="tracking.trackEvent('e-footer-info')">
              Consumer Information
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import oduLogo from '@/assets/images/odu-logo.png';
import instagram from '@/assets/images/instagram.png';
import border from '@/assets/svg/footer-border.svg';
import maps from '@/assets/svg/maps.svg';
import mail from '@/assets/svg/mail.svg';
import facebook from '@/assets/svg/facebook.svg';
import twitter from '@/assets/svg/twitter.svg';

export default {
  name: 'app-footer',
  inject: ['tracking'],
  components: {
    facebook,
    twitter,
    maps,
    mail,
    border,
  },
  props: {
    contestEnded: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    instagram,
    oduLogo,
  }),
  methods: {
    handleTrackSocial(platform) {
      this.tracking.trackEvent('e-footer-social', {
        socialPlatform: platform,
      });
    },
  },
};
</script>

<style lang="scss">
.footer {
  margin-top: 100px;
  position: relative;
  background: #0D1E31;
  &__border {
    position: absolute;
    width: 100%;
    top: 2px;
    transform: translateY(-100%);
  }
  .content {
    max-width: 1383px;
    margin: 0 auto;
    padding: 80px 50px 50px;
  }
  &__logo {
    width: 77px;
  }
  &__info {
    margin-top: 50px;
    display: flex;
    align-items: flex-start;
    &__left {
      p, a {
        font-family: $headlineFont;
        display: inline-flex;
        align-items: center;
        color: $footerBlue;
        font-size: 10px;
        line-height: 22px;
        text-decoration: none;
        margin: 20px 0;
        svg {
          width: 25px;
          height: 25px;
          margin-right: 10px;
          &.maps {
            margin-left: -5px;
          }
        }
      }
      p {
        a {
          margin: 0;
          margin-left: 8px;
        }
      }
    }
    &__right {
      margin-left: 200px;
      display: inline-flex;
      flex-direction: column;
      p {
        font-family: $headlineFont;
        color: $footerBlue;
        font-size: 10px;
        line-height: 22px;
        text-transform: uppercase;
        margin: 20px 0;
      }
      .share {
        display: flex;
        padding: 0;
        margin: 0;
        li {
          width: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: white;
          margin-right: 15px;
          a {
            &::after {
              display: none;
            }
          }
          svg, img {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
  &__bottom {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 50px;
    .copyright {
      display: block;
      a {
        display: inline-block;
      }
      span {
        display: block;
      }
    }
    a, p {
      font-family: $headlineFont;
      display: inline-flex;
      color: $footerBlue;
      font-size: 10px;
      line-height: 22px;
      text-decoration: none;
      text-transform: uppercase;
    }
    ul {
      display: flex;
      list-style: none;
      padding: 0;
      li {
        padding: 0 15px;
        position: relative;
        &:after {
          content: '';
          width: 6px;
          height: 6px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(50%, -50%);
          background: $footerBlue;
          border-radius: 50%;
        }
        &:last-child {
          padding-right: 0;
          &::after {
            display: none;
          }
        }
      }
    }
  }
  &__info, &__bottom {
    a {
      position: relative;
      &::after {
        content: '';
        width: 100%;
        height: 1px;
        background: $footerBlue;
        position: absolute;
        bottom: 0;
        left: 0;
        transform-origin: center center;
        transition: transform 300ms $easeOutQuad;
        transform: scaleX(0);
      }
      &:hover {
        &::after {
          transform: scaleX(1);
        }
      }
    }
  }
}
</style>
